import { useState, useEffect, useContext } from "react";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import UserUpload from "./UserUploadModal";
import Notification from "../../components/PopupNotification";
import { CompanyHealth, Group, Participant } from "../../types";
import UsersTable from "./UserTable";
import { PlusIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import AddParticipantModal from "./AddParticipantModal";
import { useQuery } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import Alert from "../../components/Alert";

export default function Users() {
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [currentGroupFilter, setCurrentGroupFilter] = useState<number>();

  const { getApiData } = useApi();
  const companyContext = useContext(CompanyContext);

  const {
    isPending: participantsIsPending,
    error: participantsError,
    data: participants,
  } = useQuery<Participant[]>({
    queryKey: ["participants", companyContext.companyId],
    queryFn: async () => getApiData(`/company/users`),
    initialData: [],
  });

  const { isPending: groupsIsPending, data: groups } = useQuery<Group[]>({
    queryKey: ["groups", companyContext.companyId],
    queryFn: async () => getApiData(`/company/groups/with-users`),
    initialData: [],
  });

  const filteredParticipants = currentGroupFilter ? participants.filter((p) => p.group_ids.includes(currentGroupFilter)) : participants;

  if (participantsIsPending || groupsIsPending) {
    return <Spinner />;
  }
  if (participantsError) {
    return <Alert />;
  }

  return (
    <div className="min-h-full">
      <div className="flex justify-between flex-wrap">
        <div>
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Assessment Participants</h2>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">View and manage participants and groups</p>
        </div>
        {participants.length > 0 && (
          <div className="flex lg:mt-0 mt-6 lg:pt-5">
            <Button
              text="Bulk Import"
              icon={UserGroupIcon}
              variant="secondary"
              onClick={() => {
                setShowUpload(true);
              }}
              className="mr-4"
            />
            <Button text={"Add Particpant"} icon={PlusIcon} onClick={() => setShowAddModal(true)} />
          </div>
        )}
      </div>
      <AddParticipantModal open={showAddModal} setOpen={setShowAddModal} />

      {participants.length > 0 ? (
        <div className="lg:px-4 pt-2 bg-stone-50 rounded-lg mt-6">
          <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
            <div className="mt-4">
              <div className="sm:hidden">
                <select
                  onChange={(e) => setCurrentGroupFilter(parseInt(e.target.value))}
                  className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-strataBlue"
                >
                  <option value={""}>All Participants</option>
                  {groups.map((group) => (
                    <option key={group.group_id} value={group.group_id}>
                      {group.group_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="hidden sm:block">
                <div className="-mb-px flex space-x-4 w-full overflow-x-auto">
                  <div
                    onClick={() => setCurrentGroupFilter(undefined)}
                    className={`
                      ${!currentGroupFilter ? "border-strataBlue text-strataBlue" : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"}
                      whitespace-nowrap border-b-2 px-1 pb-3 text-sm font-medium cursor-pointer`}
                  >
                    All Participants
                  </div>
                  {groups.map((group) => (
                    <div
                      key={group.group_id}
                      onClick={() => setCurrentGroupFilter(group.group_id)}
                      className={`
                      ${
                        group.group_id === currentGroupFilter
                          ? "border-strataBlue text-strataBlue"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                      }
                      whitespace-nowrap border-b-2 px-1 pb-3 text-sm font-medium cursor-pointer`}
                    >
                      {group.group_name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div>
            <UsersTable users={filteredParticipants} limit={15} />
          </div>
        </div>
      ) : (
        <div className="text-center p-5">
          <svg fill="none" stroke="currentColor" viewBox="0 0 48 48" aria-hidden="true" className="mx-auto h-12 w-12 text-gray-400 ">
            <path
              d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <h3 className="mt-2 text-gray-900 text-xl font-bold">No participants</h3>
          <p className="mt-1 text-sm text-gray-500 text-center">Get started by adding participants manually or upload a CSV file to add them in bulk</p>
          <div className="mt-6 inline-flex items-center flex-col">
            <Button text={"Add Participant"} icon={PlusIcon} onClick={() => setShowAddModal(true)} />
            <div className="text-sm text-gray-600 mt-3">-- Or --</div>
            <Button
              text="CSV Upload"
              icon={UserGroupIcon}
              variant="secondary"
              onClick={() => {
                setShowUpload(true);
              }}
              className="mt-3"
            />
          </div>
        </div>
      )}

      <UserUpload setShowUpload={setShowUpload} showUpload={showUpload} />
    </div>
  );
}
