export default function IconButton(props: {
  icon: React.ForwardRefExoticComponent<Omit<React.SVGProps<SVGSVGElement>, "ref">>;
  onClick: () => void;
  className?: string;
}) {
  const { onClick, className } = props;

  return (
    <props.icon
      className={className + " hover:cursor-pointer hover:brightness-75"}
      onClick={onClick}
    />
  );
}
