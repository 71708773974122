import colors from "tailwindcss/colors";
import { Dataset, Group, Respondent, StrataJobTitle, Survey } from "../../types";

// cutoff values
const UPPER_CUTOFF = 66.66; // above red because mental health value
const LOWER_CUTOFF = 33.33; // below green because mental health value

export function calculateStressCategoryDatasets(
    completedSurveys: Survey[],
    respondents: Respondent[],
    showCustomGroups: boolean,
    selectedStrataJobTitleFilters: number[],
    selectedGroupFilters: number[]
) {
    // calculate datasets for stress category by Group
    let stressCategoryDatasets: Dataset[] = [
        {
            label: "At-risk",
            data: [],
            color: colors.red[400],
        },
        {
            label: "Impaired",
            data: [],
            color: colors.yellow[400],
        },
        {
            label: "Optimized",
            data: [],
            color: colors.green[400],
        },
        {
            label: "Disengaged",
            data: [],
            color: colors.gray[400],
        },
    ];



    // loop over completed surveys
    for (let i = 0; i < completedSurveys.length; i++) {
        // for each survey, get total counts of red, yellow, green
        let redCountForSurvey = 0;
        let yellowCountForSurvey = 0;
        let greenCountForSurvey = 0;
        let grayCountForSurvey = 0;
        const respondentsForSuvey = respondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);
        for (let respondent of respondentsForSuvey) {
            if (showCustomGroups && selectedGroupFilters.length > 0 && !selectedGroupFilters.some((filter) => respondent.group_ids.includes(filter))) {
                continue; // skip for group filters
            }
            if (
                !showCustomGroups &&
                selectedStrataJobTitleFilters.length > 0 &&
                (!respondent.strata_job_title_id || !selectedStrataJobTitleFilters.includes(respondent.strata_job_title_id))
            ) {
                continue; // skip for strata job title filters
            }

            const mentalHealthValue = parseFloat(respondent.mental_health_value);
            if (mentalHealthValue > UPPER_CUTOFF) {
                redCountForSurvey++;
            } else if (mentalHealthValue > LOWER_CUTOFF) {
                yellowCountForSurvey++;
            } else if (mentalHealthValue > 10) {
                greenCountForSurvey++;
            } else {
                grayCountForSurvey++;
            }
        }
        stressCategoryDatasets[0].data.push({ value: redCountForSurvey, date: new Date(completedSurveys[i].start_date) }); // Red
        stressCategoryDatasets[1].data.push({ value: yellowCountForSurvey, date: new Date(completedSurveys[i].start_date) }); // Yellow
        stressCategoryDatasets[2].data.push({ value: greenCountForSurvey, date: new Date(completedSurveys[i].start_date) }); // Green
        stressCategoryDatasets[3].data.push({ value: grayCountForSurvey, date: new Date(completedSurveys[i].start_date) }); // Gray
    }

    return stressCategoryDatasets;
}


export function calculateLatestCompletedStrataJobTitleCounts(strataJobTitles: StrataJobTitle[], latestRespondents: Respondent[]) {
    const latestCompletedStrataJobTitleCounts: {
        name: string;
        red: number;
        yellow: number;
        green: number;
        gray: number;
        taken: number;
    }[] = [];

    for (const strataJobTitle of strataJobTitles) {
        const latestRespondentsWithStrataJobTitle = latestRespondents.filter((res) => res.strata_job_title_id === strataJobTitle.strata_job_title_id);
        let redCount = 0;
        let yellowCount = 0;
        let greenCount = 0;
        let grayCount = 0;
        let takenCount = 0;

        for (const respondent of latestRespondentsWithStrataJobTitle) {
            takenCount++;
            const mentalHealthValue = parseFloat(respondent.mental_health_value);
            if (mentalHealthValue > UPPER_CUTOFF) {
                redCount++;
            } else if (mentalHealthValue > LOWER_CUTOFF) {
                yellowCount++;
            } else if (mentalHealthValue > 10) {
                greenCount++;
            } else {
                grayCount++;
            }
        }
        latestCompletedStrataJobTitleCounts.push({
            name: strataJobTitle.strata_job_title_name,
            red: redCount,
            yellow: yellowCount,
            green: greenCount,
            gray: grayCount,
            taken: takenCount,
        });
    }

    return latestCompletedStrataJobTitleCounts;
}

export function calculateLatestCompletedGroupCounts(groups: Group[], latestRespondents: Respondent[]) {
    const latestCompletedGroupCounts: { name: string; red: number; yellow: number; green: number; gray: number; taken: number }[] = [];

    for (const group of groups) {
        const latestRespondentsInGroup = latestRespondents.filter((lr) => lr.group_ids && lr.group_ids.includes(group.group_id));
        let redCount = 0;
        let yellowCount = 0;
        let greenCount = 0;
        let grayCount = 0;
        let groupTakenCount = 0;

        latestRespondentsInGroup.forEach((r) => {
            groupTakenCount++;
            const mentalHealthValue = parseFloat(r.mental_health_value);
            if (mentalHealthValue > UPPER_CUTOFF) {
                redCount++;
            } else if (mentalHealthValue > LOWER_CUTOFF) {
                yellowCount++;
            } else if (mentalHealthValue > 10) {
                greenCount++;
            } else {
                grayCount++;
            }
        });

        latestCompletedGroupCounts.push({
            name: group.group_name,
            red: redCount,
            yellow: yellowCount,
            green: greenCount,
            gray: grayCount,
            taken: groupTakenCount,
        });
    };

    return latestCompletedGroupCounts;
}

export function calculateStrataJobTitleImpairedTrendDatasets(strataJobTitles: StrataJobTitle[], completedSurveys: Survey[], respondents: Respondent[]) {
    let strataJobTitleImpairedTrendDatasets: Dataset[] = strataJobTitles.map((jobTitle) => {
        return { label: jobTitle.strata_job_title_name ?? "Not Found", data: [] };
    });

    // impaired counts for each month
    for (let i = 0; i < completedSurveys.length; i++) {
        const strataJobTitlesObject: any = {};
        const respondentsForSuvey = respondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);

        for (const strataJobTitle of strataJobTitles) {
            const respondentsWithStrataJobTitle = respondentsForSuvey.filter((res) => res.strata_job_title_id === strataJobTitle.strata_job_title_id);
            let redCount = 0;
            let yellowCount = 0;
            respondentsWithStrataJobTitle.forEach((r) => {
                const mentalHealthValue = parseFloat(r.mental_health_value);
                if (mentalHealthValue > UPPER_CUTOFF) {
                    redCount++;
                } else if (mentalHealthValue > LOWER_CUTOFF) {
                    yellowCount++;
                }
            });

            const dataset = strataJobTitleImpairedTrendDatasets.find((ds) => ds.label === strataJobTitle.strata_job_title_name);
            if (dataset) {
                dataset.data[i] = { value: redCount + yellowCount, date: new Date(completedSurveys[i].start_date) };
            }
        }

        Object.values(strataJobTitlesObject).forEach((jt: any) => {
            const dataset = strataJobTitleImpairedTrendDatasets.find((d) => d.label === jt.name);
            if (dataset) {
                dataset.data[i] = { value: jt.count, date: new Date(completedSurveys[i].start_date) };
            }
        });
    }

    return strataJobTitleImpairedTrendDatasets;
}

export function calculateGroupImpairedTrendDatasets(groups: Group[], completedSurveys: Survey[], respondents: Respondent[]) {
    let groupImpairedTrendDatasets: Dataset[] = groups.map((group) => {
        return { label: group.group_name ?? "Not Found", data: [] };
    });

    // impaired counts for each month
    for (let i = 0; i < completedSurveys.length; i++) {
        const groupsObject: any = {};
        const respondentsForSuvey = respondents.filter((r) => r.survey_id === completedSurveys[i].survey_id);
        groups.forEach((group) => {
            const respondentsInGroup = respondentsForSuvey.filter((lr) => lr.group_ids && lr.group_ids.includes(group.group_id));
            let redCount = 0;
            let yellowCount = 0;
            respondentsInGroup.forEach((r) => {
                const mentalHealthValue = parseFloat(r.mental_health_value);
                if (mentalHealthValue > UPPER_CUTOFF) {
                    redCount++;
                } else if (mentalHealthValue > LOWER_CUTOFF) {
                    yellowCount++;
                }
            });

            const dataset = groupImpairedTrendDatasets.find((ds) => ds.label === group.group_name);
            if (dataset) {
                dataset.data[i] = { value: redCount + yellowCount, date: new Date(completedSurveys[i].start_date) };
            }
        });

        Object.values(groupsObject).forEach((g: any) => {
            const dataset = groupImpairedTrendDatasets.find((d) => d.label === g.name);
            if (dataset) {
                dataset.data[i] = { value: g.count, date: new Date(completedSurveys[i].start_date) };
            }
        });
    }

    return groupImpairedTrendDatasets;
}