import * as Headless from '@headlessui/react'
import React, { forwardRef } from 'react'
import { Link as ReactLink } from 'react-router-dom'


export const Link = forwardRef(function Link(
  props: {href : string} & React.ComponentPropsWithoutRef<'a'>,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  return (
    <Headless.DataInteractive>
      <ReactLink to={props.href} {...props} />
    </Headless.DataInteractive>
  )
})