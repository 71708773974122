import React, { useState, useEffect, useContext, PropsWithChildren } from "react";

import * as cognito from "../hooks/cognito";
import { ActivityType, JobTitle } from "../types";

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo: {
    username?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string;
    idToken?: string;
  };
  attrInfo?: any;
  authStatus: AuthStatus;
  claims?: any;
  mfaEnabled?: boolean;
  rememberedDevices: any[];
  signInWithEmail?: any;
  completeNewPasswordRegistration?: any;
  signOut?: any;
  verifyOTP?: (otp: string, rememberDevice?: boolean) => void;
  getSession?: any;
  sendCode?: any;
  forgotPassword?: any;
  changePassword?: any;
  getAttributes?: any;
  setAttribute?: any;
  forgetDevice?: (deviceKey: string) => void;
  setupMFA?: () => Promise<string>;
  confirmMFA?: (otp: string) => Promise<string | undefined>;
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
  rememberedDevices: [],
};

export const AuthContext = React.createContext(defaultState);

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthIsSignedIn: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>;
};

export const AuthIsNotSignedIn: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { authStatus }: IAuth = useContext(AuthContext);

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>;
};

const AuthProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [authStatus, setAuthStatus] = useState<AuthStatus>(AuthStatus.Loading);
  const [sessionInfo, setSessionInfo] = useState<any>({});
  const [attrInfo, setAttrInfo] = useState([]);
  const [claims, setClaims] = useState([]);
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(false);
  const [rememberedDevices, setRememberedDevices] = useState<any>([]);

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession();
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          idToken: session.idToken.jwtToken,
        });
        const attr: any = await getAttributes();
        const claims = attr.reduce(
          (obj: any, item: any) => ({
            ...obj,
            [item["Name"]]: item.Value,
          }),
          {}
        );

        const userData = (await getUserData()) as any;

        if (userData.PreferredMfaSetting) {
          setMfaEnabled(true);
        }

        const rememberedDevices = await cognito.listRememberedDevices();
        setRememberedDevices(rememberedDevices);

        setClaims(claims);
        setAttrInfo(attr);
        setAuthStatus(AuthStatus.SignedIn);
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut);
      }
    }
    getSessionInfo();
  }, [setAuthStatus, authStatus]);

  if (authStatus === AuthStatus.Loading) {
    return null;
  }

  async function signInWithEmail(username: string, password: string) {
    try {
      await cognito.signInWithEmail(username, password);
      setAuthStatus(AuthStatus.SignedIn);
      await recordActivity(ActivityType.Login);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function completeNewPasswordRegistration(email: string, newPassword: string, session: string, userAttributes: { given_name: string; family_name: string, phone_number: string | undefined, 'custom:job_title' : string}) {
    try {
      await cognito.completeNewPasswordRegistration(email, newPassword, session, userAttributes);
      await recordActivity(ActivityType.Register);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function signOut() {
    await recordActivity(ActivityType.Logout);
    await cognito.signOut();
    setAuthStatus(AuthStatus.SignedOut);
  }

  async function getUserData() {
    try {
      const userData = await cognito.getUserData();
      return userData;
    } catch (err) {
      throw err;
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession();
      return session;
    } catch (err) {
      throw err;
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes();
      return attr;
    } catch (err) {
      throw err;
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username);
    } catch (err) {
      throw err;
    }
  }

  async function forgotPassword(username: string, code: string, password: string) {
    try {
      await cognito.forgotPassword(username, code, password);
    } catch (err) {
      throw err;
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword);
      await recordActivity(ActivityType.ChangePassword);
    } catch (err) {
      throw err;
    }
  }

  async function verifyOTP(otp: string, rememberDevice?: boolean) {
    try {
      await cognito.verifyOTP(otp, rememberDevice);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      throw err;
    }
  }

  async function setupMFA() {
    try {
      const setupCode = (await cognito.setupMFA()) as string;
      return setupCode;
    } catch (err) {
      throw err;
    }
  }

  async function confirmMFA(otp: string) {
    try {
      await cognito.confirmMFA(otp);
      await recordActivity(ActivityType.EnableMFA);
      setMfaEnabled(true);
      return "Confirmed";
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        return err.name;
      }
      //throw err;
    }
  }

  async function forgetDevice(deviceKey: string) {
    try {
      await cognito.forgetDevice(deviceKey);
    } catch (err) {
      throw err;
    }
  }

  async function recordActivity(type: ActivityType) {
    await fetch(`${process.env.REACT_APP_API_URL}/company/activity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionInfo?.idToken ?? "",
      },
      body: JSON.stringify({ type }),
    });
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    claims,
    mfaEnabled,
    rememberedDevices,
    signInWithEmail,
    completeNewPasswordRegistration,
    signOut,
    verifyOTP,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    forgetDevice,
    setupMFA,
    confirmMFA,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
