import React, { useState, useEffect } from "react";

export default function CountdownTimer(props: { startDate?: Date; endDate: Date; refreshWhenDone?: boolean }) {
  const { startDate, endDate, refreshWhenDone } = props;

  const calculateTimeLeft = () => {
    const endTime: any = new Date(endDate);
    const startTime: any = startDate ? new Date(startDate) : new Date();
    const difference: any = endTime - startTime;
    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
      refreshWhenDone && window.location.reload();
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className="flex flex-start items-start">
      <div className={`text-sm font-semibold ${timeLeft.days < 1 ? "text-red-500" : "text-blue-500"}`}>
        {timeLeft.days} {timeLeft.days === 1 ? "Day" : "Days"} {timeLeft.hours} {timeLeft.hours === 1 ? "Hour" : "Hours"} {timeLeft.minutes}{" "}
        {timeLeft.minutes === 1 ? "Minute" : "Minutes"} {timeLeft.seconds} {timeLeft.seconds === 1 ? "Second" : "Seconds"}
      </div>
    </div>
  );
}
