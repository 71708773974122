const tabs = [
  { name: 'Sun', value: 0 },
  { name: 'Mon', value: 1 },
  { name: 'Tue', value: 2 },
  { name: 'Wed', value: 3 },
  { name: 'Thu', value: 4 },
  { name: 'Fri', value: 5 },
  { name: 'Sat', value: 6 }
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function DayOfWeek(props: {day: number, setDay: (value: number) => void}) {
  const {day, setDay} = props;

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block border rounded-lg shadow">
        <nav className="isolate flex divide-x divide-gray-200 " aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <div
              key={tab.name}
              onClick={() => setDay(tab.value)}
              className={classNames(
                tab.value === day ? 'text-gray-900 bg-gray-50' : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'cursor-pointer group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
              )}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.value === day ? 'bg-strataBlue' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </div>
          ))}
        </nav>
      </div>
    </div>
  )
}
