import { useState, useRef } from "react";
import ReactDOM from "react-dom";

export default function MultiSelectField(props: {
  values: string[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  placeholder?: string;
}) {
  const { values, selectedValues, setSelectedValues, placeholder } = props;
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredValues, setFilteredValues] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSelect = (value: string) => {
    if (!selectedValues.includes(value)) {
      const newMatch = value.match(/"([^"]+)"/) // removes Create" " from item if they clicked to create a new group
      value = newMatch ? newMatch[1] : value;
      
      setSelectedValues([...selectedValues, value]);
    }
    setInputValue("");
    setShowDropdown(false);
  };

  const handleRemove = (value: string) => {
    setSelectedValues(selectedValues.filter((v) => v !== value));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      const match = filteredValues.find((v) => v.toLowerCase() === inputValue.toLowerCase());
      const valueToAdd = match ? match : inputValue;
      handleSelect(valueToAdd);
    }
  };

  const renderDropdown = () => {
    if (!showDropdown || !inputRef.current) return null;

    const { top, left, width, height } = inputRef.current.getBoundingClientRect();

    return ReactDOM.createPortal(
      <ul
        className="absolute z-50 mt-1 bg-white border border-gray-300 rounded-md max-h-60 overflow-y-auto shadow-lg sm:text-sm sm:leading-6"
        style={{ top: top + height + window.scrollY, left, width }}
      >
        {filteredValues.map((value, index) => (
          <li key={index} onClick={() => handleSelect(value)} className="p-2 cursor-pointer hover:bg-gray-200">
            {value}
          </li>
        ))}
      </ul>,
      document.body
    );
  };

  return (
    <div className="relative">
      <div className="flex flex-wrap gap-1">
        {selectedValues.map((value, index) => (
          <div key={index} className="flex items-center bg-blue-100 text-blue-800 rounded-full px-2 py-1 text-sm mb-2">
            {value}
            <button className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none" onClick={() => handleRemove(value)}>
              &times;
            </button>
          </div>
        ))}
      </div>
      <input
        ref={inputRef}
        type="text"
        value={inputValue}
        onChange={(e) => {
          const inputValue = e.target.value;
          setInputValue(inputValue);

          let filtered = values.filter((value) => value.toLowerCase().includes(inputValue.toLowerCase()));
          if (inputValue.length > 0 && !filtered.includes(inputValue)) {
            filtered.push(`Create "${inputValue}"`);
          }
          setFilteredValues(filtered);
          setShowDropdown(true);
        }}
        onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
        onFocus={() => {
          setFilteredValues(values);
          setShowDropdown(true);
        }}
        onKeyDown={handleKeyDown}
        placeholder={placeholder ?? "Search or Create New..."}
        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
      />
      {renderDropdown()}
    </div>
  );
}
