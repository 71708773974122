import { useAuth } from "../contexts/authContext";
import { useCompanyContext } from "../contexts/companyContext";

const useApi = () => {
  const { sessionInfo, claims } = useAuth();
  const { companyId, setCompanyId } = useCompanyContext();

  const getApiData = async (endpoint: string) => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionInfo?.idToken ?? "",
        "Company-Id": companyId ? companyId.toString() : "",
      },
    });

    let body: any = null;
    try {
      body = await response.json();

      if (body.name === "TokenExpiredError") {
        window.location.reload();
      }
    } catch (err) {}

    if (response.status === 401 && body.type === "CompanyAccessDenied") {
      setCompanyId && setCompanyId(null);
    }

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    return body ? body : null;
  };

  const postApiData = async (endpoint: string, requestBody: any): Promise<{ response: Response; body: any | null }> => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionInfo?.idToken ?? "",
        "Company-Id": companyId ? companyId.toString() : "",
        "Access-Token": sessionInfo?.accessToken ?? "",
      },
      body: JSON.stringify(requestBody),
    });

    let responseBody: any = null;
    try {
      responseBody = await response.json();
    } catch {
      responseBody = null;
    }

    if (responseBody && responseBody.name === "TokenExpiredError") {
      window.location.reload();
    }

    return { response, body: responseBody };
  };

  const putApiData = async (endpoint: string, requestBody: any): Promise<{ response: Response; body: any | null }> => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionInfo?.idToken ?? "",
        "Company-Id": companyId ? companyId.toString() : "",
      },
      body: JSON.stringify(requestBody),
    });

    let responseBody: any = null;
    try {
      responseBody = await response.json();
    } catch {
      responseBody = null;
    }

    if (responseBody && responseBody.name === "TokenExpiredError") {
      window.location.reload();
    }

    return { response, body: responseBody };
  };

  const deleteApiData = async (endpoint: string): Promise<{ response: Response; body: any | null }> => {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: sessionInfo?.idToken ?? "",
        "Company-Id": companyId ? companyId.toString() : "",
      },
    });

    let responseBody: any = null;
    try {
      responseBody = await response.json();
    } catch {
      responseBody = null;
    }

    if (responseBody && responseBody.name === "TokenExpiredError") {
      window.location.reload();
    }

    return { response, body: responseBody };
  };

  return { getApiData, postApiData, putApiData, deleteApiData };
};

export default useApi;
