import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Respondent, Survey, Group, StrataJobTitle, CompanyEvent } from "../../types";
import RespondentDistribution from "./RespondentDistribution";
import RespondentCategory from "./RespondentCategory";
import Container from "../../components/Container";
import TrendChart from "../../components/TrendChart";
import GroupCategoryComparison from "./GroupCategoryComparison";
import Divider from "../../components/Divider";
import { ChevronDownIcon, LockClosedIcon, QuestionMarkCircleIcon } from "@heroicons/react/16/solid";
import { displayMonth, getInitialsFromName, getLatestCompletedSurvey } from "../../hooks/helpers";
import Dropdown from "../../components/Dropdown";
import ReportHelp from "./ReportHelp";
import { ArrowDownTrayIcon, ListBulletIcon, QueueListIcon } from "@heroicons/react/24/outline";
import Tooltip from "../../components/Tooltip";
import {
  calculateGroupImpairedTrendDatasets,
  calculateLatestCompletedGroupCounts,
  calculateLatestCompletedStrataJobTitleCounts,
  calculateStrataJobTitleImpairedTrendDatasets,
  calculateStressCategoryDatasets,
} from "./helpers";
import Checkbox from "./Checkbox";
import PDFReport from "./PDFReport";
import { usePDF, Margin, Resolution } from "react-to-pdf";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/react";

export default function Report(props: {
  surveys: Survey[];
  respondents: Respondent[];
  groups: Group[];
  strataJobTitles: StrataJobTitle[];
  companyEvents?: CompanyEvent[];
}) {
  const { surveys, respondents, groups, strataJobTitles, companyEvents } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedGroupFilters, setSelectedGroupFilters] = useState<number[]>([]);
  const [selectedStrataJobTitleFilters, setSelectedStrataJobTitleFilters] = useState<number[]>([]);
  const [showCustomGroups, setShowCustomGroups] = useState<boolean>(false);
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const surveyIdPath = parseInt(location.pathname.replace("/assessments/", ""));

  const [exporting, setExporting] = useState<boolean>(false);
  const { toPDF, targetRef } = usePDF({
    method: "open",
    filename: "page.pdf",
    page: {
      margin: Margin.MEDIUM,
    },
    resolution: 3,
  });

  let surveyId: number | null = null;
  if (!isNaN(surveyIdPath)) {
    surveyId = surveyIdPath;
  }

  if (!surveyId) {
    const latestCompletedSurvey = getLatestCompletedSurvey(surveys);

    if (latestCompletedSurvey) {
      surveyId = latestCompletedSurvey.survey_id ?? null;
    }
  } else if (!surveys?.find((s) => s.survey_id === surveyId)) {
    navigate("/home");
  }

  const survey = surveys.find((s) => s.survey_id === surveyId);
  const latestRespondents = respondents.filter((r) => r.survey_id === surveyId);
  const completedSurveys = surveys.filter((s) => new Date(s.end_date) < new Date());

  // calculate datasets for stress category by Group
  const stressCategoryDatasets = calculateStressCategoryDatasets(completedSurveys, respondents, showCustomGroups, selectedStrataJobTitleFilters, selectedGroupFilters);

  // broken down by strata job titles and groups (grouped bar)
  const latestCompletedStrataJobTitleCounts = calculateLatestCompletedStrataJobTitleCounts(strataJobTitles, latestRespondents);
  const latestCompletedGroupCounts = calculateLatestCompletedGroupCounts(groups, latestRespondents);

  // strata job title and groups impaired
  const strataJobTitleImpairedTrendDatasets = calculateStrataJobTitleImpairedTrendDatasets(strataJobTitles, completedSurveys, respondents);
  const groupImpairedTrendDatasets = calculateGroupImpairedTrendDatasets(groups, completedSurveys, respondents);

  const groupDropdownOptions = ["Custom Groups", "Clinical Groups"];

  if (!surveyId || !survey) {
    return (
      <div>
        <div className="">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Mental Health Breakdown</h2>
        </div>
        <div className="flex items-center justify-center h-96">
          <div className="flex flex-col justify-center content-center items-center">
            <LockClosedIcon className="h-14 w-14 mb-4 bg-yellow-400 rounded-full p-4" />
            <h1 className="text-xl font-bold">No Breakdown Results - Yet</h1>
            <p className="mt-4 text-gray-500 text-center">Come back after your first assessment is complete</p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div id="report" className="">
        <div className="mb-6 flex justify-between">
          {completedSurveys.length > 1 ? (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <MenuButton className="inline-flex w-full justify-center items-center gap-x-1 text-xl sm:text-2xl font-bold tracking-tight text-gray-900 px-2 py-1 rounded-md hover:bg-stone-200">
                  {displayMonth(new Date(survey.start_date).getMonth())} {new Date(survey.start_date).getFullYear()} Results
                  <ChevronDownIcon aria-hidden="true" className="-mr-1 h-7 w-7 text-gray-700" />
                </MenuButton>
              </div>

              <MenuItems
                transition
                className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              >
                {completedSurveys
                  .filter((s) => s.survey_id !== survey.survey_id)
                  .map((s, index) => (
                    <div className="py-1">
                      <MenuItem>
                        <div
                          onClick={() => navigate(`/assessments/${s.survey_id}`)}
                          className="cursor-pointer block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:forced-color-adjust-none data-[focus]:forced-colors:bg-[Highlight] data-[focus]:forced-colors:text-[HighlightText]"
                        >
                          {displayMonth(new Date(s.start_date).getMonth())} {new Date(s.start_date).getFullYear()}
                        </div>
                      </MenuItem>
                    </div>
                  ))}
              </MenuItems>
            </Menu>
          ) : (
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">
              {displayMonth(new Date(survey.start_date).getMonth())} {new Date(survey.start_date).getFullYear()} Results
            </h2>
          )}
          <div className="flex gap-3">
            {exporting ? (
              <svg className="h-5 w-5 animate-spin text-gray-600 mt-1" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <Tooltip message="Download a PDF version of this report" position="below">
                <ArrowDownTrayIcon
                  className="w-6 h-6 text-gray-600 hover:text-gray-900 cursor-pointer"
                  onClick={() => {
                    setExporting(true);
                    toPDF();
                    setTimeout(() => {
                      setExporting(false);
                    }, 3000);
                  }}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <RespondentCategory
          responseRate={survey.response_rate ? parseFloat(survey.response_rate) : 0}
          green={survey.green_count ?? 0}
          yellow={survey.yellow_count ?? 0}
          red={survey.red_count ?? 0}
          gray={survey.disengaged_count ?? 0}
          totalRecipients={survey.total_recipients}
        />

        <ReportHelp openHelp={openHelp} setOpenHelp={setOpenHelp} />

        <div className="mt-8 mb-8">
          <Divider heading={""} />
        </div>

        <div className="flex items-center mb-4">
          <div className="mr-4">
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">How is Your Team Experiencing Stress</h2>
          </div>
          <div className="relative group">
            <QuestionMarkCircleIcon className="h-6 w-6 cursor-pointer" onClick={() => setOpenHelp(!openHelp)} />

            <div className="absolute right-1/2 transform translate-x-1/2 -top-[70px] hidden w-48 p-3 text-sm text-white bg-black rounded-lg group-hover:block">
              Learn more about what this measures
              <div className="absolute bottom-[-6px] left-1/2 transform -translate-x-1/2 w-3 h-3 bg-black rotate-45"></div>
            </div>
          </div>
        </div>

        <Container title="" className="col-span-3 h-96 mb-4">
          <RespondentDistribution respondents={latestRespondents} />
        </Container>

        <div>
          <div className="mb-4 mt-12">
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Group Stress Breakdown</h2>
          </div>

          <Container title="" className="relative col-span-3 mb-4 h-96">
            {latestCompletedGroupCounts.length > 0 && (
              <div className="absolute z-20">
                <Dropdown
                  options={groupDropdownOptions}
                  selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                  setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
                />
              </div>
            )}
            <GroupCategoryComparison groupCounts={showCustomGroups ? latestCompletedGroupCounts : latestCompletedStrataJobTitleCounts} />
          </Container>
        </div>

        <div className="mb-4 mt-12">
          <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Stress Trend Over Time</h2>
        </div>

        <div className="mb-6 grid grid-cols-5 gap-4">
          <Container className={`lg:col-span-4 md:col-span-3 col-span-5 w-full h-96 relative`}>
            <TrendChart
              datasets={stressCategoryDatasets}
              stepSize={1}
              suggestedMin={0}
              suggestedMax={5}
              calcType="add"
              yAxisUnits="Respondents"
              showPercentageToggle={true}
              companyEvents={companyEvents}
            />
          </Container>

          <Container title="Filter" className="lg:col-span-1 md:col-span-2 col-span-5 w-full h-96 lg:px-5">
            {latestCompletedGroupCounts.length > 0 && (
              <Dropdown
                options={groupDropdownOptions}
                selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
                className="mb-2 mt-2 z-20"
              />
            )}
            <div className="h-[265px] overflow-scroll pl-1">
              {showCustomGroups
                ? groups.map((group) => (
                    <Checkbox
                      key={group.group_id}
                      id={group.group_id}
                      name={group.group_name}
                      checked={!!selectedGroupFilters.find((filter) => filter === group.group_id)}
                      setChecked={(checked: boolean) => {
                        if (checked) {
                          setSelectedGroupFilters((prevSelected) => [...prevSelected, group.group_id]);
                        } else {
                          setSelectedGroupFilters((prevSelected) => prevSelected.filter((item) => item !== group.group_id));
                        }
                      }}
                    />
                  ))
                : strataJobTitles.map((strataJobTitle) => (
                    <Checkbox
                      key={strataJobTitle.strata_job_title_id}
                      id={strataJobTitle.strata_job_title_id}
                      name={strataJobTitle.strata_job_title_name}
                      checked={!!selectedStrataJobTitleFilters.find((filter) => filter === strataJobTitle.strata_job_title_id)}
                      setChecked={(checked: boolean) => {
                        if (checked) {
                          setSelectedStrataJobTitleFilters((prevSelected) => [...prevSelected, strataJobTitle.strata_job_title_id]);
                        } else {
                          setSelectedStrataJobTitleFilters((prevSelected) => prevSelected.filter((item) => item !== strataJobTitle.strata_job_title_id));
                        }
                      }}
                    />
                  ))}
            </div>
          </Container>
        </div>

        <div>
          <div className="mb-4 mt-12">
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Number of Individuals in Elevated Stress Zones (Red and Yellow) by Group</h2>
          </div>
          <Container className="lg:col-span-4 md:col-span-3 col-span-5 w-full h-96 relative">
            <div className="absolute flex items-center gap-2">
              {/* <h3 className="text-sm font-medium text-gray-500 mr-2">Groups in the Red and Yellow</h3> */}
              {latestCompletedGroupCounts.length > 0 && (
                <Dropdown
                  options={groupDropdownOptions}
                  selected={showCustomGroups ? groupDropdownOptions[0] : groupDropdownOptions[1]}
                  setSelected={(selected) => (selected === groupDropdownOptions[0] ? setShowCustomGroups(true) : setShowCustomGroups(false))}
                  className="z-20 flex"
                />
              )}
            </div>
            <TrendChart
              datasets={showCustomGroups ? groupImpairedTrendDatasets : strataJobTitleImpairedTrendDatasets}
              suggestedMax={5}
              suggestedMin={0}
              calcType="add"
              yAxisUnits="Respondents"
              showPercentageToggle={true}
              companyEvents={companyEvents}
            />
          </Container>
        </div>

        <div>
          <div className="mb-4 mt-12">
            <h2 className="text-xl font-bold tracking-tight text-gray-800 sm:text-2xl">Response Rate Over Time</h2>
          </div>
          <Container className="lg:col-span-4 md:col-span-3 col-span-5 w-full h-96 relative">
            <TrendChart
              datasets={[
                {
                  label: "Response Rate",
                  data: completedSurveys.map((s) => ({
                    value: parseFloat(s.response_rate ?? "0") / 100,
                    date: s.start_date,
                  })),
                },
              ]}
              // stepSize={10}
              suggestedMax={100}
              suggestedMin={0}
              calcType="average"
              displayType="percent"
              yAxisLabel="Response Rate"
              companyEvents={companyEvents}
              hideLegend={true}
            />
          </Container>
        </div>
        <div id="printableReport" ref={targetRef} className="absolute -top-[10000px] -left-[10000px]">
          {" "}
          {/* Render printable version off screen */}
          <PDFReport surveyId={surveyId} surveys={surveys} respondents={respondents} groups={groups} strataJobTitles={strataJobTitles} companyEvents={companyEvents} />
        </div>
      </div>
    );
  }
}
