import { useQuery } from "@tanstack/react-query";
import InfoCard from "../../components/InfoCard";
import { Company, Respondent, Survey, SurveyKey, YearsExperience } from "../../types";
import { useContext } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import { getLatestCompletedSurvey } from "../../hooks/helpers";
import colors from "tailwindcss/colors";

export default function SIValueFactors() {
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();

  const companyId = companyContext.companyId;

  const {
    isPending: surveysIsPending,
    error: surveysError,
    data: surveys,
  } = useQuery<Survey[]>({
    queryKey: ["surveys", companyId],
    queryFn: () => getApiData(`/company/surveys`),
    enabled: !!companyId,
  });

  const { data: company } = useQuery<Company>({
    queryKey: ["company", companyContext.companyId],
    queryFn: () => getApiData(`/company/info`),
  });

  const latestCompletedSurvey = surveys ? getLatestCompletedSurvey(surveys) : null;

  const { error: surveyKeysError, data: surveyKeys } = useQuery<SurveyKey[]>({
    queryKey: ["surveyKeys", latestCompletedSurvey?.survey_id, companyId],
    queryFn: async () => getApiData(`/company/surveys/surveykeys/${latestCompletedSurvey?.survey_id}`),
    enabled: !!latestCompletedSurvey,
  });

  const { error: respondentsError, data: respondents } = useQuery<Respondent[]>({
    queryKey: ["respondents", latestCompletedSurvey?.survey_id],
    queryFn: async () => getApiData(`/company/surveys/respondents/${latestCompletedSurvey?.survey_id}`),
    enabled: !!latestCompletedSurvey,
  });

  const { data: tenures } = useQuery<YearsExperience[]>({
    queryKey: ["tenures"],
    queryFn: async () => getApiData(`/company/info/years-experience`),
    enabled: !!latestCompletedSurvey,
  });

  // Mental Health
  let mentalCategory = "";
  let mentalDescription = "";
  let mentalColor: string = colors.gray[500];
  if (latestCompletedSurvey) {
    const mentalHealthValue = latestCompletedSurvey.mental_health_value ? parseFloat(latestCompletedSurvey.mental_health_value) : 0;
    if (mentalHealthValue > 26.66) {
      mentalCategory = "At-Risk";
      mentalDescription = "Team is experiencing extreme levels of stress and dysfunction";
      mentalColor = colors.red[500];
    } else if (mentalHealthValue > 23.33) {
      mentalCategory = "Warning";
      mentalDescription = "Team is experiencing increasing levels of stress and dysfunction";
      mentalColor = colors.yellow[500];
    } else {
      mentalCategory = "Optimized";
      mentalDescription = "Team is optimally engaged and experiencing manageable levels of stress";
      mentalColor = colors.green[500];
    }
  }

  // Response Rate
  let latestSurveyResponseRate: number | null = null;
  let disengagedIndividuals = 0;
  if (surveyKeys && respondents) {
    latestSurveyResponseRate = (respondents.length / surveyKeys.length) * 100;
    disengagedIndividuals = respondents.filter((r) => parseFloat(r.mental_health_value) <= 10).length;
  }

  // Lifestyle
  let lifestyleCategory = "";
  let lifestyleDescription = "";
  let lifestyleColor: string = "";
  if (latestCompletedSurvey) {
    const lifestyleValue =
      (parseFloat(latestCompletedSurvey.sleep_value ?? "0") +
        parseFloat(latestCompletedSurvey.hydration_value ?? "0") +
        parseFloat(latestCompletedSurvey.diet_value ?? "0") +
        parseFloat(latestCompletedSurvey.exercise_value ?? "0")) /
      4;

    if (lifestyleValue > 66.66) {
      lifestyleCategory = "At-Risk";
      lifestyleDescription = "The team is inactive, eats poorly, is often dehydrated, and lacks sufficient sleep";
      lifestyleColor = colors.red[500];
    } else if (lifestyleValue > 33.33) {
      lifestyleCategory = "Needs Improvement";
      lifestyleDescription = "The team exercises and eats healthy sometimes, but often skips workouts, meals, water intake, and sleep";
      lifestyleColor = colors.yellow[500];
    } else {
      lifestyleCategory = "Optimized";
      lifestyleDescription = "The team exercises daily, eats balanced meals, stays hydrated, and gets adequate sleep";
      lifestyleColor = colors.green[500];
    }
  }

  // Tenure
  let tenureDescripton = "";
  let averageTenure = 0;
  if (respondents && tenures) {
    let totalTenureScore = 0;
    respondents.forEach((r) => {
      totalTenureScore += r.years_experience_id;
    });
    averageTenure = totalTenureScore / respondents.length;
    let tenureId = Math.round(averageTenure);
    const tenure = tenures.find((t) => t.years_experience_id === tenureId);
    tenureDescripton = tenure ? `${tenure.years_experience_name} years` : "";
  }
  return (
    <div className="mt-8 grid grid-cols-2 sm:grid-cols-5 gap-4">
      <InfoCard
        title="Mental"
        impact="High Impact"
        subHeader={mentalCategory}
        subHeaderColor={mentalColor}
        description={mentalDescription}
        route="results"
        className="col-span-2 sm:col-span-1"
      />
      <InfoCard
        title="Assessment Metrics"
        impact="Medium Impact"
        subHeader="Response Rate"
        description={latestSurveyResponseRate !== null ? `${latestSurveyResponseRate.toFixed(0)}%` : ""}
        subHeader2="Disengaged Individuals"
        //subHeader2Color={responseRateColor}
        description2={disengagedIndividuals.toString()}
        className="col-span-2"
        route="results"
      />
      <InfoCard
        title="Lifestyle"
        impact="Low Impact"
        subHeader={lifestyleCategory}
        subHeaderColor={lifestyleColor}
        description={lifestyleDescription}
        route="results"
        className="col-span-2 sm:col-span-1"
      />

      <InfoCard
        title="Tenure"
        impact="Low Impact"
        subHeader={"Average Tenure"}
        description={tenureDescripton}
        route="results"
        className="col-span-2 sm:col-span-1"
      />
    </div>
  );
}
