export default function BulletSteps(props: { step: number; setStep: (value: number) => void; count: number }) {
  const { step, setStep, count } = props;

  return (
    <nav className="flex justify-center mt-3" aria-label="Progress">
      <ol className="flex lg:space-x-5 space-x-2">
        {Array.from(Array(count).keys()).map((s) => (
          <li key={s}>
            {s < step ? (
              <div onClick={() => setStep(s)} className="block h-2.5 w-2.5 rounded-full bg-strataBlue hover:bg-blue-900">
                <span className="sr-only">{s}</span>
              </div>
            ) : s === step ? (
              <div onClick={() => setStep(s)} className="relative flex items-center justify-center" aria-current="step">
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-indigo-200" />
                </span>
                <span className="relative block h-2.5 w-2.5 rounded-full bg-strataBlue" aria-hidden="true" />
                <span className="sr-only">{s}</span>
              </div>
            ) : (
              <div onClick={() => setStep(s)} className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
                <span className="sr-only">{s}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
