import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Company, Survey } from "../../types";
import colors from "tailwindcss/colors";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export default function CompanyDistributions(props: { companies: Company[]; surveys: Survey[] }) {
  const { companies, surveys } = props;

  const data: { labels: string[]; datasets: { label: string; data: (number | null)[]; backgroundColor: string }[] } = {
    labels: [],
    datasets: [
      {
        label: "Red",
        data: [],
        backgroundColor: colors.red[300],
      },
      {
        label: "Yellow",
        data: [],
        backgroundColor: colors.yellow[300],
      },
      {
        label: "Green",
        data: [],
        backgroundColor: colors.green[400],
      },
      {
        label: "Disengaged",
        data: [],
        backgroundColor: colors.gray[300],
      },
    ],
  };

  for (const company of companies) {
    data.labels.push(company.company_name);
    const survey = surveys.find((s) => s.company_id === company.company_id);
    if (survey) {
      const totalRespondents = survey.red_count! + survey.yellow_count! + survey.green_count! + survey.disengaged_count!;
      data.datasets[0].data.push(survey.red_count ? survey.red_count / totalRespondents : null);
      data.datasets[1].data.push(survey.yellow_count ? survey.yellow_count / totalRespondents : null);
      data.datasets[2].data.push(survey.green_count ? survey.green_count / totalRespondents : null);
      data.datasets[3].data.push(survey.disengaged_count ? survey.disengaged_count / totalRespondents : null);
    } else {
      data.datasets[0].data.push(null);
      data.datasets[1].data.push(null);
      data.datasets[2].data.push(null);
      data.datasets[3].data.push(null);
    }
  }

  return (
    <div className="h-full">
      <Bar
        data={data}
        options={{
          indexAxis: "y",
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "top",
              display: false
            },
            datalabels: {
              display: (context) => context.dataset.data[context.dataIndex] as number >= .04, // hide number when smaller than 4%
              formatter: (value) => (value ? `${(value * 100).toFixed(0)}%` : null), // Format values as percentages
              color: (value) => colors.gray[500],
              anchor: "start",
              align: "end",
              font: {
                //weight: "bold",
                size: 20
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              max: 1,
              ticks: {
                display: false,
                stepSize: 1,
                format: {
                  style: "percent",
                  maximumFractionDigits: 1,
                },
                callback: (value) => {
                    return `${parseFloat(value as string) * 100}%`
                }
              },
            },
            y: {
              stacked: true,
            },
          },
        }}
      />
    </div>
  );
}
