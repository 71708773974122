import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

export default function AddAdministrators() {
  return (
    <div className="mt-36">
      <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-green-100">
        <CheckIcon className="h-10 w-10 text-green-600" aria-hidden="true" />
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <h3 className="font-semibold leading-6 text-gray-900 text-2xl">
          Your all set!
        </h3>
        <div className="mt-2">
          <p className="text-lg text-gray-500">
            Thanks for setting up your account, now let's get started!
          </p>
        </div>
      </div>
    </div>
  );
}
