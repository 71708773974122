import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import PaymentForm from "./PaymentForm";
import Spinner from "./Spinner";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import useApi from "../hooks/useApi";

export default function Payment(props: { reloadOnSuccess?: boolean }) {
  const { reloadOnSuccess } = props;
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!}`);
  const { postApiData } = useApi();

  const [clientSecret, setClientSecret] = useState<string>();
  const [alreadySubscribed, setAlreadySubscribed] = useState<boolean>(false);
  const [noSubscription, setNoSubscription] = useState<boolean>(false);

  useEffect(() => {
    async function createSubscriptionIntent() {
      try {
        const {response, body } = await postApiData("/company/payment/create-subscription", {})

        if(response.status === 404) {
          setNoSubscription(true);
          return;
        }

        // company already has an active subscription
        if (response.status === 409) {
          setAlreadySubscribed(true);
          return;
        }

        setClientSecret(body.latest_invoice.payment_intent.client_secret);
      } catch (err) {
        console.error(err);
      }
    }

    createSubscriptionIntent();
  }, []);

  if (alreadySubscribed) {
    return (
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 mt-5">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Payment info already added
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">Please proceed to the next step</p>
          </div>
        </div>
      </div>
    );
  }

  if (noSubscription) {
    return (
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100 mt-5">
          <XMarkIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Subscription not found
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">No subscription was found for your company, please contact Strata Intel support to add a subscription</p>
          </div>
        </div>
      </div>
    );
  }

  if (!clientSecret || !stripePromise) {
    return <Spinner />;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: { theme: "stripe" },
      }}
    >
      <PaymentForm clientSecret={clientSecret} onSucess={reloadOnSuccess ? () => setTimeout(() => window.location.reload(), 1000) : () => { return }} />
    </Elements>
  );
}
