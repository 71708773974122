import { useState, useEffect } from "react";
import { useNotifications } from "../../contexts/notificationContext";
import { Transition } from "@headlessui/react";
import { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function Notification(props: { id: number; title: string; message: string; type: "success" | "error" | "warn" }) {
  const { id, title, message, type } = props;
  const notifications = useNotifications();
  const [isShowing, setIsShowing] = useState(true);

  useEffect(() => {
    // auto clear after 
    const SECONDS = 7;
    setTimeout(() => setIsShowing(false), SECONDS * 1000);
  }, []);

  return (
    <Transition
      show={isShowing}
      appear={true}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0 translate-x-4"
      enterTo="opacity-100 translate-x-0"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-4"
      afterLeave={() => notifications.removeNotification(id)}
    >
      <div className="pointer-events-auto w-full max-w-lg overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {type === "error" ? (
                <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
              ) : type === "warn" ? (
                <ExclamationTriangleIcon className="h-6 w-6 text-yellow-500" aria-hidden="true" />
              ) : (
                <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              <p className="text-sm font-medium text-gray-900">{title}</p>
              <p className="mt-1 text-sm text-gray-500">{message}</p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                onClick={() => {
                  setIsShowing(false);
                }}
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-strataBlue focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
