import { ChevronDownIcon } from "@heroicons/react/16/solid";
import { NavbarItem, NavbarLabel } from "../Nav/NavBar";
import Dropdown from "../../components/Dropdown";
import { useState } from "react";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export default function EconomicImpactTable(props: { stats: any, setSelectedSince: (option: string) => void, selectedSince: string }) {

  const {setSelectedSince, selectedSince} = props;

  return (
    <div className="">
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                    Metric
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Current 
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    <Dropdown options={["Last Month", "Last Quarter", "Last Year"]} selected={selectedSince} setSelected={setSelectedSince} />
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Value Change
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {props.stats.map((metric: any, index: number) => (
                  <tr key={index} className="even:bg-gray-50">
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">{metric.name}</td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-gray-900 sm:pl-3">
                      {formatter.format(metric.value)}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-md font-medium text-gray-900 sm:pl-3">
                      { metric.percentageChange === 0 ? `--` : `${formatter.format(metric.percentageChange)}`}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-red-500">{metric.percentageChange === 0 ? `--` : `${formatter.format(metric.change)}` }
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
