const tabs = [
  { name: '6M',  current: false },
  { name: '1Y', current: false },
  { name: 'YTD', current: false },
  { name: 'MAX', current: false },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function RangeSelector( props: {selectedDate: string, setSelectedDate: (value: string) => void }) {
  return (
    <div>
      <div className="">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block rounded-md border-gray-300 focus:border-gray-500 focus:ring-gray-500 text-sm pr-6 py-2"
          defaultValue={props.selectedDate}
          onChange={(e) => props.setSelectedDate(e.target.value)}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
    </div>
  )
}
