export default function Checkbox(props: { id: number | string; name: string, checked: boolean, setChecked: (value: boolean) => void }) {
  const { id, name, checked, setChecked } = props;
  return (
    <fieldset key={id} className="mb-2">
      <legend className="sr-only">{name}</legend>
      <div className="space-y-5">
        <div className="relative flex items-start">
          <div className="flex h-6 items-center">
            <input
              id={id.toString()}
              aria-describedby={`${name}`}
              name={name}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-strataBlue focus:ring-strataBlue"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor={id.toString()} className="font-medium text-gray-900">
              {name}
            </label>{" "}
            <span id={`${name}-description`} className="text-gray-500">
              <span className="sr-only">{name}</span>
            </span>
          </div>
        </div>
      </div>
    </fieldset>
  );
}
