import { Fragment, useContext, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router";
import { CognitoUser } from "amazon-cognito-identity-js";
import { AuthContext } from "../../contexts/authContext";
import Tooltip from "../../components/Tooltip";
import Button from "../../components/Button";
import { CognitoAdmin, CompanyRole } from "../../types";
import useApi from "../../hooks/useApi";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import Dropdown from "../../components/Dropdown";
import { useNotifications } from "../../contexts/notificationContext";

const statusExplanations = {
  UNCONFIRMED: "User has been created but not confirmed.",
  CONFIRMED: "User has been confirmed.",
  UNKNOWN: "User status isn't known.",
  RESET_REQUIRED: "User is confirmed, but the user must request a code and reset their password before they can sign in.",
  FORCE_CHANGE_PASSWORD:
    "The user is confirmed and the user can sign in using a temporary password, but on first sign-in, the user must change their  password to a new value before doing anything else.",
} as any;

export default function AdminModal(props: { open: boolean; setOpen: (open: boolean) => void; details?: CognitoAdmin; companyId?: number }) {
  const { open, setOpen, details, companyId } = props;
  const cancelButtonRef = useRef(null);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { postApiData, deleteApiData, getApiData } = useApi();
  const queryClient = useQueryClient();
  const companyContext = useContext(CompanyContext);
  const notifications = useNotifications();

  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [newUsername, setNewUsername] = useState<string>();
  const [error, setError] = useState<string>();
  const [selectedRoleName, setSelectedRoleName] = useState<string>("Admin");

  const { data: companyRoles } = useQuery<CompanyRole[]>({
    queryKey: ["companyRoles", companyId],
    queryFn: async () => getApiData(`/company/roles`),
    initialData: [],
  });

  const {
    data: admins,
  } = useQuery<CognitoAdmin[]>({
    queryKey: ["admins", companyContext.companyId],
    queryFn: async () => getApiData(`/company/admin`),
    initialData: []
  });

  function getValueByName(name: string, attributes: Array<{ Name: string; Value: string }>): string | undefined {
    const pair = attributes.find((pair) => pair.Name === name);
    return pair ? pair.Value : undefined;
  }

  async function createAdminUser(): Promise<void> {
    //check to see if valid email
    if (
      !newUsername ||
      !newUsername.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    ) {
      setError("Must be a valid email");
      return;
    }
    try {
      setSubmitting(true);
      const selectedRole: CompanyRole = companyRoles.find((r) => r.company_role_name === selectedRoleName)!;

      if(admins.some(a => getValueByName("email", a.UserAttributes) === newUsername)){
        setError("User already exists")
        setSubmitting(false)
        return;
      }
      
      const { response, body } = await postApiData("/company/admin", [{ email: newUsername, companyRoleId: selectedRole.company_role_id }]);
      if (response.status !== 200) {
        console.error(body);
        alert("An error occured creating new admin");
        setSubmitting(false);
        return;
      }
      notifications.addNotification("Success", "User created successfully");
      queryClient.invalidateQueries({ queryKey: ["admins", companyContext.companyId] });
      setOpen(false);
      setSubmitting(false);
      setNewUsername("");
    } catch (err) {
      console.error(err);
    }
  }

  async function deleteAdmin() {
    try {
      if (auth.sessionInfo?.idToken && details) {
        setSubmitting(true);

        const { response } = await deleteApiData(`/company/admin/${encodeURIComponent(details.Username)}`);

        if(response.status === 200) {
          setOpen(false);
          notifications.addNotification("Success", "Successfully deleted user");
          setSubmitting(false);
          queryClient.invalidateQueries({ queryKey: ["admins", companyContext.companyId] });
        } else {
          notifications.addNotification("Error", "There was an error deleting user, please try again later", "error");
          setSubmitting(false);
        }
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function resendTempPassword() {
    try {
      if (auth.sessionInfo?.idToken && details) {
        setSubmitting(true);
        const { response } = await postApiData(`/company/admin/resend-temp-password`, { email: getValueByName("email", details.UserAttributes)});

        if(response.status === 200) {
          setOpen(false);
          notifications.addNotification("Success", "Successfully resent temporary password");
          setSubmitting(false);
        } else {
          notifications.addNotification("Error", "There was an error resending password, please try again later", "error");
          setSubmitting(false);
        }
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Existing admin details
  if (details?.Username) {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => {
            setOpen(false);
            setConfirmDelete(false);
          }}
        >
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div>
                    <div className="">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Details
                      </Dialog.Title>
                      <div>
                        <div className="mt-6">
                          <dl className="grid grid-cols-1 sm:grid-cols-12">
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-4 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">Name</dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {getValueByName("given_name", details.UserAttributes)} {getValueByName("family_name", details.UserAttributes)}
                              </dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-4 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">Role</dt>
                              {/* TODO get actual roles */}
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{'Admin'}</dd> 
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-4 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">User Status</dt>
                              <dd className="flex mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                <div className="mr-1">{details.UserStatus}</div><Tooltip message={statusExplanations[details.UserStatus]} />
                              </dd>
                              {details.UserStatus === "FORCE_CHANGE_PASSWORD" && (
                                <Button text="Send New Temporary Password" size="sm" variant="warning" onClick={() => resendTempPassword()} submitting={submitting} />
                              )}
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-6 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">Email</dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{getValueByName("email", details.UserAttributes)}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-6 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">Phone Number</dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{getValueByName("phone_number", details.UserAttributes)}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-6 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">Last Modified Date</dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {new Date(details.UserLastModifiedDate).toLocaleDateString()} {new Date(details.UserLastModifiedDate).toLocaleTimeString()}
                              </dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-6 sm:px-0">
                              <dt className="text-sm font-medium leading-6 text-gray-900">User Created Date</dt>
                              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                {new Date(details.UserCreateDate).toLocaleDateString()} {new Date(details.UserCreateDate).toLocaleTimeString()}
                              </dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <Button
                      text={confirmDelete ? "Confirm Delete?" : "Delete User"}
                      variant="danger"
                      className="sm:col-start-2"
                      onClick={() => (confirmDelete ? deleteAdmin() : setConfirmDelete(true))}
                      submitting={submitting}
                    />
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => {
                        setOpen(false);
                        setConfirmDelete(false);
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  } else {
    //Create new admin
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add a New User
                      </Dialog.Title>
                      <div className="flex justify-between">
                        <div className="mt-5 w-4/5">
                          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                            Email Address
                          </label>
                          <input
                            className="w-full mr-3 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            type="text"
                            onChange={(e) => setNewUsername(e.target.value)}
                            placeholder=""
                          />
                          <p className="text-red-500 text-sm">{error}</p>
                        </div>
                        <div className="mt-5">
                          <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-2">
                            Role
                          </label>
                          <Dropdown options={companyRoles.map((role) => role.company_role_name)} selected={selectedRoleName} setSelected={setSelectedRoleName} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <Button text="Cancel" variant="secondary" onClick={() => setOpen(false)} />
                    <Button text="Add" type="button" onClick={() => createAdminUser()} submitting={submitting} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
}
