import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import Button from "./Button";
import { CheckIcon } from "@heroicons/react/24/outline";
import Spinner from "./Spinner";

export default function PaymentForm(props: { clientSecret: string; onSucess: () => void }) {
  const stripe = useStripe();
  const elements = useElements();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [paid, setPaid] = useState<boolean>(false);
  const [paymentDetails, setPaymentDetails] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    async function getPaymentDetails() {
      const details = await stripe?.retrievePaymentIntent(props.clientSecret);
      setPaymentDetails(details?.paymentIntent);
    }

    getPaymentDetails();
  }, [stripe, props.clientSecret]);

  async function handleSubmit(event: any) {
    event.preventDefault();

    setSubmitting(true);

    if (!elements) {
      return;
    }

    const confirmationResult = await stripe!.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        //return_url: "https://example.com/order/123/complete",
      },
      redirect: "if_required",
    });

    if (confirmationResult.error) {
      setErrorMessage(confirmationResult.error.message);
      setSubmitting(false);
      return;
    }

    if (confirmationResult.paymentIntent?.status === "succeeded") {
      setPaid(true);
      setSubmitting(false);
      props.onSucess();
      return;
    }

    //TODO: look into this, when paying with bank account it comes back as processing
    if (confirmationResult.paymentIntent?.status === "processing") {
      setPaid(true);
      setSubmitting(false);
      return;
    }

    setSubmitting(false);
  }

  if (paid) {
    return (
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100 mt-5">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Payment made successfully
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">Please proceed to the next step</p>
          </div>
        </div>
      </div>
    );
  }

  if (paymentDetails) {
    return (
      <form className="" onSubmit={handleSubmit}>
        <PaymentElement
          options={{
            paymentMethodOrder: ["us_bank_account", "card"],
          }}
        />
        <div className="mt-2 text-sm text-gray-500">
          Strata Intel Monthly Subscription:{" "}
          <span className="text-gray-800">${paymentDetails.amount / 100}.00</span>
        </div>
        {errorMessage && <div className="mt-2 text-sm text-red-500">{errorMessage}</div>}
        <Button
          type="submit"
          className="mt-1 w-full"
          text={`Subscribe - Total Due $${paymentDetails.amount / 100}.00`}
          disabled={!stripe}
          submitting={submitting}
        />
      </form>
    );
  }

  return <Spinner />;
}
