import React from 'react';

export default function GaugeLegend(){
  return (
    <div className="flex items-center space-x-4 whitespace-nowrap text-xs md:text-base">
      <div className="flex items-center space-x-2">
        <div className="w-4 h-4 bg-red-500 rounded-full"></div>
        <span className="text-xs text-gray-500">At risk</span>
      </div>
      <div className="flex items-center space-x-2">
        <div className="w-4 h-4 bg-yellow-500 rounded-full"></div>
        <span className="text-xs text-gray-500">Elevated</span>
      </div>
      <div className="flex items-center space-x-2">
        <div className="w-4 h-4 bg-green-500 rounded-full"></div>
        <span className="text-xs text-gray-500">Optimized</span>
      </div>
    </div>
  );
};