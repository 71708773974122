import { Fragment, useState, useContext, useEffect } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";
import { AuthContext } from "../../contexts/authContext";
import { JobTitle, Participant, Group } from "../../types";
import { formatPhoneNumber, stripNonNumbers } from "../../hooks/helpers";
import Button from "../../components/Button";
import { XMarkIcon } from "@heroicons/react/24/outline";
import IconButton from "../../components/IconButton";
import AutocompleteField from "../../components/AutocompleteField";
import { useQueryClient } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { useNotifications } from "../../contexts/notificationContext";

export default function AssignParticipantGroupsModal(props: {
  participants: Participant[];
  open: boolean;
  setOpen: (value: boolean) => void;
  setParticipants: (participants: Participant[]) => void
}) {
  const { participants, open, setOpen, setParticipants } = props;

  const queryClient = useQueryClient();
  const companyContext = useContext(CompanyContext);
  const { getApiData, postApiData } = useApi();
  const notificationContext = useNotifications();

  const [group, setGroup] = useState<string>("");
  const [error, setError] = useState<string>("");

  const { data: groups } = useQuery<Group[]>({
    queryKey: ["groups", companyContext.companyId],
    queryFn: async () => getApiData(`/company/groups/with-users`),
    initialData: [],
  });

  useEffect(() => {
    if(!open) {
      setGroup("");
    }
  }, [open])

  async function assignGroup() {
    if(group.trim().length === 0){
      setError("Group name is required");
      return;
    }

    const { response } = await postApiData(`/company/groups/assign`, { participants, groupName: group.trim() });
    if(response.status === 201) {
      if(setParticipants) {
        setParticipants([]);
      }
      notificationContext.addNotification("Success", "Participants successfully added to group");
      queryClient.invalidateQueries({ queryKey: ["participants", companyContext.companyId] });
      queryClient.invalidateQueries({ queryKey: ["groups", companyContext.companyId] });
      setOpen(false);
    }
  }

  return (      
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={setOpen}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="flex justify-between">
                  <h3 className="text-xl leading-6 font-medium text-gray-800 mb-5">Assign Participant Groups</h3>
                  <IconButton icon={XMarkIcon} className="h-7 text-gray-500" onClick={() => setOpen(false)} />
                </div>

                <div>
                  <h6>The following participants will be assigned to groups:</h6>
                  <ul className="mt-2">
                    {participants.map(participant => (
                      <li key={participant.user_id} className={"text-sm text-gray-700"}>{participant.email} - {participant.phone_number}</li>
                    ))}
                  </ul>

                  <div className="mt-5">
                    <AutocompleteField value={group} setValue={setGroup} values={groups.map(g => g.group_name)} />
                  </div>
                  {error && <div className="text-sm text-red-500">{error}</div>}
                </div>

                <div className="mt-5 sm:mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <Button text="Cancel" type="button" variant="secondary" className="w-full" onClick={() => setOpen(false)} />
                  <Button text="Assign" type="button" className="w-full" onClick={() => assignGroup()}  />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
