export default function SearchInput(props: { filter: string; setFilter: (value: string) => void; className?: string }) {
  return (
    <div className={`relative ml-1 ${props.className}`}>
      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none z-20 ">
        <svg
          className="shrink-0 w-4 h-4 text-stone-500 ml-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8" />
          <path d="m21 21-4.3-4.3" />
        </svg>
      </div>
      <input
        type="text"
        className="py-[7px] pl-10 pr-8 block w-full z-10 bg-stone-100 border-transparent rounded-lg text-sm placeholder:text-stone-500 focus:border-blue-700 focus:ring-blue-700 disabled:opacity-50 disabled:pointer-events-none"
        placeholder="Search Participants"
        onChange={(e) => props.setFilter(e.target.value)}
      />
    </div>
  );
}
