import Button from "../../components/Button";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/authContext";
import { useContext } from "react";
import Logo from "../../assets/Logo.png";
import Tooltip from "../../components/Tooltip";
import { useSearchParams } from "react-router-dom";
import { formatPhoneNumber, stripNonNumbers } from "../../hooks/helpers";

export default function Registration() {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const session = searchParams.get("session");

  return (
    <div className="h-screen bg-gray-50">
      <div className="min-h-full flex flex-col py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={Logo} alt="Strata Intel" />
          <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">Welcome New User!</h2>
          <p className="mt-2 text-center text-sm text-gray-600">Please create your account</p>
        </div>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            phone: "",
            jobTitle: "",
            password: "",
            confirmPassword: "",
            all: "",
          }}
          validate={(values) => {
            const errors: any = {};
            if (!values.firstName) {
              errors.firstName = "First name is required";
            }
            if (!values.lastName) {
              errors.lastName = "Last name is required";
            }
            const phoneDigits = stripNonNumbers(values.phone);
            if (values.phone && (phoneDigits.length < 10 || phoneDigits.length > 12)) {
              errors.phone = "Phone number is invalid";
            }
            if (!values.jobTitle) {
              errors.jobTitle = "Job title is required";
            }
            if (!values.password) {
              errors.password = "New password is required";
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = "Password confirmation is required";
            }
            if (values.password !== values.confirmPassword) {
              errors.all = "Passwords must match";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            setSubmitting(true);
            try {
              await auth.completeNewPasswordRegistration(email, values.password, session, {
                given_name: values.firstName,
                family_name: values.lastName,
                phone_number: values.phone ? `+1${stripNonNumbers(values.phone)}` : undefined,
                'custom:job_title': values.jobTitle
              });
              navigate("/home?welcome");
            } catch (err: any) {
              setErrors({ all: err.message });
            }
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <div className="mt-8 mx-auto sm:w-full sm:max-w-lg w-[90%]">
              <div className="bg-white py-8 px-4 shadow sm:rounded sm:px-10">
                <Form className="space-y-6">
                  <div className="sm:flex gap-3">
                    <div className="flex-1">
                      <label className="block text-sm font-medium text-gray-700" htmlFor="firstName">
                        First Name
                      </label>
                      <Field
                        type="text"
                        name="firstName"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                        placeholder="First Name"
                      />
                      <ErrorMessage className="text-red-500 text-xs mt-1" name="firstName" component="div" />
                    </div>
                    <div className="mt-5 sm:mt-0 flex-1">
                      <label className="block text-sm font-medium text-gray-700" htmlFor="lastName">
                        Last Name
                      </label>
                      <Field
                        type="text"
                        name="lastName"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                        placeholder="Last Name"
                      />
                      <ErrorMessage className="text-red-500 text-xs mt-1" name="lastName" component="div" />
                    </div>
                  </div>
                  <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="phone">
                      Cell Phone
                    </label>
                    <Field
                      type="text"
                      name="phone"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                      placeholder="(555) 555-5555"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue("phone", formatPhoneNumber(e.target.value))}
                    />
                    <ErrorMessage className="text-red-500 text-xs mt-1" name="phone" component="div" />
                  </div>
                  <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="jobTitle">
                      Job Title
                    </label>
                    <Field
                      type="text"
                      name="jobTitle"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                      placeholder="Job Title"
                    />
                    <ErrorMessage className="text-red-500 text-xs mt-1" name="jobTitle" component="div" />
                  </div>
                  <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="password">
                      New Password
                    </label>
                    <Field
                      type="password"
                      name="password"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                      placeholder="Password"
                    />
                    <ErrorMessage className="text-red-500 text-xs mt-1" name="password" component="div" />
                  </div>
                  <div className="mt-5">
                    <label className="block text-sm font-medium text-gray-700" htmlFor="confirmPassword">
                      Confirm Password
                    </label>
                    <Field
                      type="password"
                      name="confirmPassword"
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded shadow-sm placeholder-gray-400 focus:outline-none focus:ring-strataBlue focus:border-strataBlue sm:text-sm"
                      placeholder="Confirm Password"
                    />
                    <ErrorMessage className="text-red-500 text-xs mt-1" name="confirmPassword" component="div" />
                  </div>
                  <div className="text-sm text-gray-600">
                    <div className="text-sm font-medium text-gray-700">Password requirements:</div>
                    <ul className="list-disc ml-4">
                      <li>Contains at least 1 number</li>
                      <li>
                        <div className="flex">
                          Contains at least 1 special character{" "}
                          <span className="ml-1">
                            <Tooltip message={`The following characters count as special characters: ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ + =`} />
                          </span>
                        </div>
                      </li>
                      <li>Contains at least 1 uppercase letter</li>
                      <li>Contains at least 1 lowercase letter</li>
                    </ul>
                  </div>
                  <ErrorMessage className="text-red-500 text-sm mt-5" name="all" component="div" />
                  <div className="flex w-full items-baseline mt-7">
                    <Button type="submit" text="Register" submitting={isSubmitting} className="w-full" />
                  </div>
                </Form>
                <div className="mt-6 text-[15px]">
                  <p>By clicking Register, you agree to our <a href="https://strataintel.com/terms"  target="_blank" className="underline text-strataBlue">Terms of Service</a> and <a href="https://strataintel.com/privacy"  target="_blank" className="underline text-strataBlue">Privacy Statement</a>.</p>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  );
}
