import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import { ExclamationTriangleIcon, InformationCircleIcon, PencilIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CompanyEvent } from "../types";
import { useContext, useEffect, useState } from "react";
import Button from "./Button";
import useApi from "../hooks/useApi";
import { useQueryClient } from "@tanstack/react-query";
import { CompanyContext } from "../contexts/companyContext";
import { useNotifications } from "../contexts/notificationContext";

export default function CompanyEventModal(props: { open: boolean; setOpen: (value: boolean) => void; companyEvent?: CompanyEvent | null }) {
  const { open, setOpen, companyEvent } = props;

  const { putApiData, deleteApiData, postApiData } = useApi();
  const queryClient = useQueryClient();
  const { companyId } = useContext(CompanyContext);
  const notifications = useNotifications();

  const [name, setName] = useState<string>(companyEvent?.event_name ?? "");
  const [description, setDescription] = useState<string>(companyEvent?.event_description ?? "");
  const [date, setDate] = useState<string>(companyEvent?.event_date ? new Date(companyEvent.event_date).toISOString().split("T")[0] : "");

  const [editable, setEditable] = useState<boolean>(false);
  const [confirmRemove, setConfirmRemove] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (companyEvent) {
      setName(companyEvent.event_name);
      setDescription(companyEvent.event_description ?? "");
      setDate(new Date(companyEvent.event_date).toISOString().split("T")[0]);
    }
    if (!open) {
      setEditable(false);
      setConfirmRemove(false);
      setName("");
      setDescription("");
      setDate("");
      setErrors([]);
    }
  }, [companyEvent, open]);

  function validate(): boolean {
    const errors: string[] = [];
    if (!name) {
      errors.push("Event name is required");
    }
    if (!date) {
      errors.push("Date is required");
    }
    if (errors.length > 0) {
      setErrors(errors);
      return false;
    }
    return true;
  }

  async function createCompanyEvent() {
    if (!validate()) {
      return;
    }
    const { response } = await postApiData(`/company/info/event`, {
      event_name: name,
      event_description: description,
      event_date: date,
    });

    if (response.ok) {
      await queryClient.invalidateQueries({ queryKey: ["companyEvents", companyId] });
      notifications.addNotification("Success!", "Event created successfully", "success");
      setOpen(false);
    } else {
      console.error(response);
      notifications.addNotification("Error", "There was an error updating the event, please try again later", "error");
    }
  }

  async function updateCompanyEvent() {
    if (!companyEvent || !validate()) {
      return;
    }
    const { response } = await putApiData(`/company/info/event/${companyEvent.company_event_id}`, {
      event_name: name,
      event_description: description,
      event_date: date,
    });

    if (response.ok) {
      await queryClient.invalidateQueries({ queryKey: ["companyEvents", companyId] });
      notifications.addNotification("Success!", "Event updated successfully", "success");
      setOpen(false);
    } else {
      console.error(response);
      notifications.addNotification("Error", "There was an error updating the event, please try again later", "error");
    }
  }

  async function deleteCompanyEvent() {
    if (!companyEvent) {
      return;
    }
    const { response } = await deleteApiData(`/company/info/event/${companyEvent.company_event_id}`);

    if (response.ok) {
      await queryClient.invalidateQueries({ queryKey: ["companyEvents", companyId] });
      notifications.addNotification("Success!", "Event removed successfully", "success");
      setOpen(false);
    } else {
      console.error(response);
      notifications.addNotification("Error", "There was an error updating the event, please try again later", "error");
    }
  }

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            {!companyEvent ? (
              <div className="">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                    Add Event
                  </DialogTitle>
                  <p className="mt-2 text-gray-600 text-sm">Add an event to clearly mark a significant moment</p>
                  <div className="mt-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        Event Name
                      </label>
                      <input
                        id="name"
                        placeholder="Name..."
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div className="mt-3">
                      <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                        Description
                      </label>
                      <textarea
                        id="description"
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description..."
                        className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div className="mt-3">
                      <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">
                        Date
                      </label>
                      <input
                        id="date"
                        type="date"
                        min="2023-01-01"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                {errors.length > 0 && <div className="text-red-500 text-sm mt-4">{errors?.join(", ")}</div>}
                <div className="mt-7 flex justify-between">
                  <Button text="Cancel" variant="secondary" onClick={() => setOpen(false)} />
                  <Button text="Add" onClick={createCompanyEvent} />
                </div>
              </div>
            ) : editable ? (
              <div className="">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                    Update Event
                  </DialogTitle>
                  <div className="mt-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        Event Name
                      </label>
                      <input
                        id="name"
                        placeholder="Name..."
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div className="mt-3">
                      <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                        Description
                      </label>
                      <textarea
                        id="description"
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description..."
                        className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div className="mt-3">
                      <label htmlFor="date" className="block text-sm font-medium leading-6 text-gray-900">
                        Date
                      </label>
                      <input
                        id="date"
                        type="date"
                        min="2023-01-01"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="block px-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                {errors.length > 0 && <div className="text-red-500 text-sm mt-4">{errors?.join(", ")}</div>}
                <div className="mt-7 flex justify-between">
                  <Button text="Cancel" variant="secondary" onClick={() => setEditable(false)} />
                  <Button text="Update" onClick={updateCompanyEvent} />
                </div>
              </div>
            ) : (
              <div className="">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" className="text-lg font-semibold leading-6 text-gray-900">
                    {companyEvent?.event_name}
                  </DialogTitle>
                  <div className="mt-2">
                    <div className="text-gray-800 text-sm font-semibold">{companyEvent?.event_date && new Date(companyEvent.event_date).toLocaleDateString()}</div>
                    <div className="text-gray-600 text-sm mt-1">{companyEvent?.event_description}</div>
                  </div>
                </div>
                <div className="mt-10 flex justify-between">
                  <Button text="Update" icon={PencilIcon} variant="secondary" onClick={() => setEditable(true)} />
                  <Button
                    text={confirmRemove ? "Confirm Remove?" : "Remove"}
                    icon={TrashIcon}
                    variant="danger"
                    onClick={() => (confirmRemove ? deleteCompanyEvent() : setConfirmRemove(true))}
                  />
                </div>
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
