import { TrashIcon } from "@heroicons/react/16/solid";
import { PlusIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

export default function AddAdministrators(props: { emails: Array<string>; setEmails: (emails: Array<string>) => void }) {
  const { emails, setEmails } = props;
  const [email, setEmail] = useState<string>("");

  return (
    <div className="h-full">
      <div className="max-w-lg mx-auto">
        <div className="py-5 px-auto sm:flex sm:mt-10">
          <div className="w-full sm:max-w-md ">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="you@example.com"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <button
            className="p-2 mt-3 inline-flex w-full items-center justify-center rounded-full bg-strataBlue text-sm font-semibold text-white shadow-sm hover:bg-blue-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-strataBlue sm:ml-3 sm:mt-0 sm:w-auto"
            onClick={() => {
              setEmails([...emails, email]);
              setEmail("");
            }}
          >
            <PlusIcon className="h-5" />
          </button>
        </div>
        {emails.length > 0 && (
          <ul className="ml-1">
            {emails.map((email, index) => (
              <li key={index} className="text-gray-500 text-sm mb-2 flex items-center border-b pb-2">
                {email}
                <span
                  className="border ml-2 p-1 rounded-md cursor-pointer text-gray-500 hover:text-red-500"
                  onClick={() => props.setEmails(props.emails.filter((e) => e !== email))}
                >
                  <TrashIcon className="h-4 w-4" />
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
