import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

export default function Tooltip(props: { message: string; children?: ReactNode }) {
  const { message, children } = props;

  return (
    <div className="group relative flex">
      {children ? children : <InformationCircleIcon className="h-5" />}
      <span className="absolute top-6 -left-1/2 scale-0 transition-all rounded bg-white p-3 text-gray-700 font-light group-hover:scale-100 z-50 w-52 shadow-lg">
        {message}
      </span>
    </div>
  );
}
