import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../../contexts/authContext";
import { useQuery } from "@tanstack/react-query";
import { CompanyHealth, JobTitle, StrataJobTitle } from "../../types";
import useApi from "../../hooks/useApi";
import { CompanyContext } from "../../contexts/companyContext";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";
import Alert from "../../components/Alert";
import { useNotifications } from "../../contexts/notificationContext";

export default function Participants() {
  const [jobTitleChanges, setJobTitleChanges] = useState<JobTitle[]>([]);
  const auth = useContext(AuthContext);
  const { getApiData, putApiData } = useApi();
  const companyContext = useContext(CompanyContext);
  const notifications = useNotifications();

  const { isPending: strataJobTitlesIsPending, data: strataJobTitles } = useQuery<StrataJobTitle[]>({
    queryKey: ["strataJobTitles", companyContext.companyId],
    queryFn: async () => getApiData(`/company/info/strata-job-titles`),
    initialData: [],
  });

  const { isPending: companyJobTitlesIsPending, data: companyJobTitles } = useQuery<JobTitle[]>({
    queryKey: ["companyJobTitles", companyContext.companyId],
    queryFn: async () => getApiData(`/company/info/job-titles`),
    initialData: [],
  });

  async function updateJobTitleMappings() {
    const { response, body } = await putApiData("/company/info/job-titles", jobTitleChanges);

    if (response.status === 200) {
      notifications.addNotification("Success", "Mappings updated successfully");
      setJobTitleChanges([]);
    } else {
      console.error(response);
      console.error(body);
      notifications.addNotification("Error", "An unexpected error occurred, please try again later", "error");
    }
  }

  if (strataJobTitlesIsPending || companyJobTitlesIsPending) {
    return <Spinner />;
  }

  if (strataJobTitles.length === 0 || companyJobTitles.length === 0) {
    return (
      <div>
        <div className="px-4 sm:px-0 pb-5 border-b mb-4">
          <h3 className="text-base font-semibold leading-7 text-gray-900">Participant Mapping</h3>
          <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Match the job tilte name clinical Strata Intel job category</p>
        </div>
        <Alert
          title="No Job Titles"
          message="No job titles have been assigned to participants. Please assign job titles, then come back to assign mappings."
          type="Warning"
        />
      </div>
    );
  }

  return (
    <div className="">
      <div className="px-4 sm:px-0 pb-5 border-b ">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Participant Mapping</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Match the job tilte name clinical Strata Intel job category</p>
      </div>

      <div>
        <div className="-mx-4 mb-5 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Facility Title
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                  Strata Intel Title
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {companyJobTitles.map((companyTitle, index) => (
                <tr key={index}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                    {companyTitle.company_job_title_name}
                  </td>
                  <td className=" px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    <select
                      className="bg-gray-50 rounded-md border border-gray-300"
                      defaultValue={companyTitle.strata_job_title_id ?? 0}
                      onChange={(e) => {
                        companyTitle.strata_job_title_id = parseInt(e.target.value) > 0 ? parseInt(e.target.value) : null;
                        const index = jobTitleChanges.findIndex((cjt: any) => cjt.company_job_title_id === companyTitle.company_job_title_id);
                        if (index > -1) {
                          let copyOfState = [...jobTitleChanges];
                          copyOfState[index] = companyTitle;
                          setJobTitleChanges(copyOfState);
                        } else {
                          setJobTitleChanges([...jobTitleChanges, companyTitle]);
                        }
                      }}
                    >
                      <option value={0}>-- No Job Title Assigned --</option>
                      {strataJobTitles.map((strataTitle, index) => (
                        <option key={index} value={strataTitle.strata_job_title_id}>
                          {strataTitle.strata_job_title_name}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {jobTitleChanges.length > 0 && <Button text="Update Mapping" onClick={() => updateJobTitleMappings()} />}
      </div>
    </div>
  );
}
