import { useQuery } from "@tanstack/react-query";
import { Company, CompanySurveySummary, Survey } from "../../types";
import { useContext } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import { Avatar } from "../Nav/Avatar";
import { getInitialsFromName, getLatestCompletedSurvey } from "../../hooks/helpers";
import CompanyOverview from "../Home/CompanyOverview";

export default function TeamOverview() {
  const { companyId } = useContext(CompanyContext);
  const { getApiData } = useApi();

  const { data: company } = useQuery<Company>({
    queryKey: ["company", companyId],
    queryFn: () => getApiData(`/company/info`),
  });

  const { data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys", companyId],
    queryFn: async () => getApiData(`/company/surveys`),
    initialData: [],
  });

  const { data: companySurveys } = useQuery<CompanySurveySummary[]>({
    queryKey: ["companySurveys", companyId],
    queryFn: () => getApiData(`/company/surveys/get/latest-completed/siblings`),
    enabled: !!companyId,
    initialData: [],
  });

  return (
    <div>
      <h1 className="text-2xl font-bold mb-6">Team Overview</h1>
      <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
        {companySurveys.map((companySurvey, index) => (
          <div className="overflow-hidden rounded-xl border border-gray-300">
            <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-blue-50 px-6 py-4">
              <Avatar slot="icon" initials={getInitialsFromName(companySurvey.company_name)} className="bg-strataBlue text-white h-12 w-12 flex-none rounded-lg object-cover ring-1 ring-gray-900/10" />
              <div className="text-sm font-medium leading-6 text-gray-900">{companySurvey.company_name}</div>
            </div>
            <dl className="divide-y divide-gray-200 px-6 text-sm leading-6 bg-white">
              <div className="flex justify-between gap-x-4 py-4">
                <dt className="text-gray-500">SI Value</dt>
                <dd className="text-gray-700">
                  {companySurvey.si_value ? (
                    <div>
                      <span className={`${parseFloat(companySurvey.si_value) > 66.66 ? "text-green-500" : parseFloat(companySurvey.si_value) > 33.33 ? "text-yellow-500" : "text-red-500"}`}>
                        {parseFloat(companySurvey.si_value).toFixed(1)}
                      </span>{" "}
                      / 100
                    </div>
                  ) : (
                    <div className="text-gray-400">No Results</div>
                  )}
                </dd>
              </div>
              <div className="flex justify-between gap-x-4 py-4">
                <dt className="text-gray-500">Response Distribution</dt>
                {companySurvey.si_value ? (
                  <dd className="flex items-start gap-x-1">
                    <div className="rounded-md text-xs font-medium ring-1 ring-red-600 ring-inset bg-red-500 text-white w-8 h-6 flex items-center justify-center">{companySurvey.red_count}</div>
                    <div className="rounded-md text-xs font-medium ring-1 ring-yellow-600 ring-inset bg-yellow-500 text-white w-8 h-6 flex items-center justify-center">
                      {companySurvey.yellow_count}
                    </div>
                    <div className="rounded-md text-xs font-medium ring-1 ring-green-600 ring-inset bg-green-500 text-white w-8 h-6 flex items-center justify-center">{companySurvey.green_count}</div>
                    <div className="rounded-md text-xs font-medium ring-1 ring-gray-500 ring-inset bg-gray-400 text-white w-8 h-6 flex items-center justify-center">
                      {companySurvey.disengaged_count}
                    </div>
                  </dd>
                ) : (
                  <div className="text-gray-400">No Results</div>
                )}
              </div>
            </dl>
          </div>
        ))}
      </ul>
    </div>
  );
}
