import { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Respondent, Survey, Group, StrataJobTitle, CompanyEvent, Company } from "../../types";
import RespondentDistribution from "./RespondentDistribution";
import RespondentCategory from "./RespondentCategory";
import Container from "../../components/Container";
import TrendChart from "../../components/TrendChart";
import GroupCategoryComparison from "./GroupCategoryComparison";
import Divider from "../../components/Divider";
import Logo from "../../assets/Logo.png";
import {
  calculateGroupImpairedTrendDatasets,
  calculateLatestCompletedGroupCounts,
  calculateLatestCompletedStrataJobTitleCounts,
  calculateStrataJobTitleImpairedTrendDatasets,
  calculateStressCategoryDatasets,
} from "./helpers";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { CompanyContext } from "../../contexts/companyContext";
import { displayMonth } from "../../hooks/helpers";

export default function PDFReport(props: {
  surveyId: number;
  surveys: Survey[];
  respondents: Respondent[];
  groups: Group[];
  strataJobTitles: StrataJobTitle[];
  companyEvents?: CompanyEvent[];
}) {
  const { surveyId, surveys, respondents, groups, strataJobTitles, companyEvents } = props;

  const { getApiData } = useApi();
  const { companyId } = useContext(CompanyContext);

  const { data: company } = useQuery<Company>({
    queryKey: ["company", companyId],
    queryFn: () => getApiData(`/company/info`),
  });

  const survey = surveys.find((s) => s.survey_id === surveyId);
  const latestRespondents = respondents.filter((r) => r.survey_id === surveyId);
  const completedSurveys = surveys.filter((s) => new Date(s.end_date) < new Date());

  if (!survey) {
    return <div>Not Found</div>;
  }

  // calculate datasets for stress category by Group
  const stressCategoryDatasets = calculateStressCategoryDatasets(completedSurveys, respondents, true, [], []);

  // broken down by strata job titles and groups (grouped bar)
  const latestCompletedStrataJobTitleCounts = calculateLatestCompletedStrataJobTitleCounts(strataJobTitles, latestRespondents);
  const latestCompletedGroupCounts = calculateLatestCompletedGroupCounts(groups, latestRespondents);

  // strata job title and groups impaired
  const strataJobTitleImpairedTrendDatasets = calculateStrataJobTitleImpairedTrendDatasets(strataJobTitles, completedSurveys, respondents);
  const groupImpairedTrendDatasets = calculateGroupImpairedTrendDatasets(groups, completedSurveys, respondents);

  return (
    <div id="report" className="w-[1240px]">
      {/* A4 Page Dimenstions: 1240 x 1754 for 150 PPI */}
      {/* Page 1 */}
      <div className="h-[1700px]">
        <div>
          <img src={Logo} className="w-72" />
        </div>
        <div className="mt-2 mb-10 flex justify-between">
          <h2 className="font-bold tracking-tight text-gray-800 text-2xl">
            {company?.company_name} {displayMonth(new Date(survey.start_date).getMonth())} {new Date(survey.start_date).getFullYear()} Report
          </h2>
        </div>
        <RespondentCategory
          responseRate={survey.response_rate ? parseFloat(survey.response_rate) : 0}
          green={survey.green_count ?? 0}
          yellow={survey.yellow_count ?? 0}
          red={survey.red_count ?? 0}
          gray={survey.disengaged_count ?? 0}
          totalRecipients={survey.total_recipients}
          printable={true}
        />

        <div className="mt-8 mb-8">
          <Divider heading={""} />
        </div>

        <div className="flex items-center mb-4">
          <div className="mr-4">
            <h2 className="font-bold tracking-tight text-gray-800 text-2xl">How is Your Team Experiencing Stress</h2>
          </div>
        </div>

        <Container title="" className="col-span-3 h-96 mb-4">
          <RespondentDistribution respondents={latestRespondents} printable={true} />
        </Container>

        {latestCompletedGroupCounts.length > 0 && (
          <div>
            <div className="mb-4 mt-12">
              <h2 className="font-bold tracking-tight text-gray-800 text-2xl">Group Stress Breakdown (Custom Groups)</h2>
            </div>
            <Container title="" className="relative col-span-3 mb-4 h-96">
              <GroupCategoryComparison groupCounts={latestCompletedGroupCounts} printable={true} />
            </Container>
          </div>
        )}

        {latestCompletedStrataJobTitleCounts.length > 0 && (
          <div>
            <div className="mb-4 mt-12">
              <h2 className="font-bold tracking-tight text-gray-800 text-2xl">Group Stress Breakdown (Clinical Groups)</h2>
            </div>
            <Container title="" className="relative col-span-3 mb-4 h-96">
              <GroupCategoryComparison groupCounts={latestCompletedStrataJobTitleCounts} printable={true} />
            </Container>
          </div>
        )}
      </div>

      {/* Page 2 */}
      <div className="h-[1754px] mt-28">
        <div className="mb-4">
          <h2 className="font-bold tracking-tight text-gray-800 text-2xl">Stress Trend Over Time</h2>
        </div>

        <Container className={`w-full h-96 relative mb-6`}>
          <TrendChart
            datasets={stressCategoryDatasets}
            stepSize={1}
            suggestedMin={0}
            suggestedMax={5}
            calcType="add"
            yAxisUnits="Respondents"
            showPercentageToggle={true}
            companyEvents={companyEvents}
            printable={true}
          />
        </Container>

        {groupImpairedTrendDatasets.length > 0 && (
          <div>
            <div className="mb-4 mt-12">
              <h2 className="font-bold tracking-tight text-gray-800 text-2xl">Number of Individuals in Elevated Stress Zones (Red and Yellow) by Group</h2>
            </div>
            <Container className="w-full h-96 relative">
              <TrendChart
                datasets={groupImpairedTrendDatasets}
                suggestedMax={5}
                suggestedMin={0}
                calcType="add"
                yAxisUnits="Respondents"
                showPercentageToggle={true}
                companyEvents={companyEvents}
                printable={true}
              />
            </Container>
          </div>
        )}

        <div>
          <div className="mb-4 mt-12">
            <h2 className="font-bold tracking-tight text-gray-800 text-2xl">Number of Individuals in Elevated Stress Zones (Red and Yellow) by Clinical Category</h2>
          </div>
          <Container className="w-full h-96 relative">
            <TrendChart
              datasets={strataJobTitleImpairedTrendDatasets}
              suggestedMax={5}
              suggestedMin={0}
              calcType="add"
              yAxisUnits="Respondents"
              showPercentageToggle={true}
              companyEvents={companyEvents}
              printable={true}
            />
          </Container>
        </div>
      </div>
    </div>
  );
}
