import React, { useEffect, useRef } from 'react';

export default function OTPInput(props: { otp: string, setOtp: (otp: string) => void; }) {
  const { otp, setOtp } = props;
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;

    // Update OTP state
    const newOtp = otp.split('');
    newOtp[index] = value;
    const newOtpString = newOtp.join('');
    setOtp(newOtpString);

    // Move to the next input
    if (value) {
      inputRefs.current[index + 1]?.focus();
    }

    if(newOtp.length >= 6) {
      inputRefs.current[index]?.blur()
    } 
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  useEffect(() => {
    // Auto-focus on the first input when the component mounts
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);


  return (
    <div className="flex justify-between space-x-2 mt-2">
      {[0, 1, 2, 3, 4, 5].map((value, index) => (
        <input
          key={index}
          type="number"
          maxLength={1}
          value={otp[index] ?? ""}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className="w-12 h-12 border-2 border-gray-300 rounded text-center text-xl focus:outline-none focus:border-strataBlue [&::-webkit-inner-spin-button]:appearance-none"
          ref={(el) => (inputRefs.current[index] = el!)}
        />
      ))}
    </div>
  );
};