import { useState, useContext, useEffect } from "react";
import { Dialog, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { AuthContext } from "../../contexts/authContext";
import { Group, JobTitle, Survey } from "../../types";
import { formatPhoneNumber, getCurrentSurvey, stripNonNumbers } from "../../hooks/helpers";
import Button from "../../components/Button";
import { XMarkIcon } from "@heroicons/react/24/outline";
import IconButton from "../../components/IconButton";
import AutocompleteField from "../../components/AutocompleteField";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { CompanyContext } from "../../contexts/companyContext";
import MultiSelectField from "../../components/MultiSelectField";
import { useNotifications } from "../../contexts/notificationContext";

export default function AddParticipantModal(props: {
  open: boolean;
  setOpen: (value: boolean) => void;
}) {
  const { open, setOpen } = props;

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [jobTitle, setJobTitle] = useState<string>("");
  const [groupNames, setGroupNames] = useState<string[]>([]);
  const [hireDate, setHireDate] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>();
  const [addToCurrentSurvey, setAddToCurrentSurvey] = useState<boolean>(true);

  const companyContext = useContext(CompanyContext);
  const { getApiData, postApiData } = useApi();
  const queryClient = useQueryClient();
  const notification = useNotifications();

  const companyId = companyContext.companyId;

  const { data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys", companyId],
    queryFn: () => getApiData(`/company/surveys`),
    enabled: !!companyId,
    initialData: [],
  });

  const { data: groups } = useQuery<Group[]>({
    queryKey: ["groups", companyContext.companyId],
    queryFn: async () => getApiData(`/company/groups/with-users`),
    initialData: [],
  });

  const {
    data: companyJobTitles,
  } = useQuery<JobTitle[]>({
    queryKey: ["companyJobTitles", companyContext.companyId],
    queryFn: async () => getApiData(`/company/info/job-titles`),
    initialData: [],
  });

  const currentSurvey = getCurrentSurvey(surveys);

  useEffect(() => {
    setFirstName("");
    setLastName("");
    setJobTitle("");
    setEmail("");
    setPhone("");
    setGroupNames([]);
    setHireDate("");
    setValidationMessage("");
  }, [open]);

  async function addUser() {
    try {
      setSubmitting(true);

      if (!email && !phone) {
        setValidationMessage("Please enter either email or phone number");
        setSubmitting(false);
        return;
      }

      const { response: userResponse, body: userBody } = await postApiData("/company/users", {
        first_name: firstName,
        last_name: lastName,
        email,
        phone_number: stripNonNumbers(phone),
        job_title: jobTitle,
        hire_date: hireDate,
        group_names: groupNames,
        addToCurrentSurvey,
      });

      if (userResponse.status === 400 && userBody.type === "DuplicateUser") {
        const errors = userBody.errors as string[];
        setValidationMessage(errors.join(". "));
        setSubmitting(false);
        return;
      }

      if (userResponse.status === 204) {
        setOpen(false);
        notification.addNotification("Success!", "Participant added successfully", "success");
        queryClient.invalidateQueries({ queryKey: ["participants", companyId] });
        queryClient.invalidateQueries({ queryKey: ["groups", companyId] });
      } else {
        notification.addNotification("Error", "An error occurred, please try again later", "error");
      }

      setSubmitting(false);
    } catch (err: any) {
      console.error(err);
      setSubmitting(false);
    }
  }

  return (
    <Transition show={open}>
      <Dialog className="relative z-10" onClose={setOpen}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="flex justify-between">
                  <h3 className="text-xl leading-6 font-medium text-gray-800 mb-5">Add Particpant</h3>
                  <IconButton icon={XMarkIcon} className="h-7 text-gray-500" onClick={() => setOpen(false)} />
                </div>
                

                <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">

                <div className="sm:col-span-3">
                    <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
                      First Name
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-strataBlue sm:max-w-md">
                        <input
                          type="text"
                          name="firstName"
                          id="firstName"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          onChange={(e) => setFirstName(e.target.value)}
                          value={firstName}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
                      Last Name
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-strataBlue sm:max-w-md">
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          name="lastName"
                          id="phone"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          onChange={(e) => setLastName(e.target.value)}
                          value={lastName}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                      Email
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-strataBlue sm:max-w-md">
                        <input
                          type="text"
                          name="email"
                          id="email"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">
                      Phone
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-strataBlue sm:max-w-md">
                        <input
                          type="tel"
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          name="phone"
                          id="phone"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          onChange={(e) => setPhone(e.target.value)}
                          value={formatPhoneNumber(phone)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                     Job Title
                    </label>
                    <div className="mt-2">
                      <div className="">
                        <AutocompleteField
                          value={jobTitle}
                          setValue={(value: string) => setJobTitle(value)}
                          values={companyJobTitles.map((jt) => jt.company_job_title_name)}
                          placeholder="Search or Add New..."
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label htmlFor="groupNames" className="block text-sm font-medium leading-6 text-gray-900">
                      Groups
                    </label>
                    <div className="mt-2">
                      <div className="">
                        <MultiSelectField
                          selectedValues={groupNames}
                          setSelectedValues={(value: string[]) => setGroupNames(value)}
                          values={groups.map((g) => g.group_name)}
                          placeholder="Search or Add New..."
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-red-500 text-sm mt-5">{validationMessage}</div>

                {currentSurvey && (
                  <div className="relative flex gap-x-3 mt-8">
                    <div className="flex h-6 items-center">
                      <input
                        id="offers"
                        name="offers"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-strataBlue focus:ring-strataBlue"
                        checked={addToCurrentSurvey}
                        onChange={(e) => setAddToCurrentSurvey(e.target.checked)}
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="offers" className="font-medium text-gray-900">
                        Send Assessment Invite?
                      </label>
                      <p className="text-gray-500 text-sm">
                        An active assessment is in progress. Select if you want this participant to participate in the current assessment.
                      </p>
                    </div>
                  </div>
                )}

                <div className="mt-5 sm:mt-8 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <Button text="Cancel" type="button" variant="secondary" className="w-full" onClick={() => setOpen(false)} />
                  <Button text="Add" type="button" className="w-full" onClick={() => addUser()} submitting={submitting} />
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
