import { Bar } from "react-chartjs-2";
import colors from "tailwindcss/colors";

export default function(props: { red: number, yellow: number, green: number, disengaged: number}) {
    const { red, yellow, green, disengaged } = props;

    const totalRespondents = red + yellow + green + disengaged;

    return (
        <Bar
        data={{
          labels: [""],
          datasets: [
            {
              label: "Red",
              data: [red / totalRespondents],
              backgroundColor: colors.red[300],
              barThickness: 100,
            },
            {
              label: "Yellow",
              data: [yellow / totalRespondents],
              backgroundColor: colors.yellow[300],
              barThickness: 100,
            },
            {
              label: "Green",
              data: [green / totalRespondents],
              backgroundColor: colors.green[400],
              barThickness: 100,
            },
            {
              label: "Disengaged",
              data: [disengaged / totalRespondents],
              backgroundColor: colors.gray[300],
              barThickness: 100,
            },
          ],
        }}
        options={{
          indexAxis: "y",
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: 0,
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: (context) => (context.dataset.data[context.dataIndex] as number) > 0.06, // hide number when smaller than 7%
              formatter: (value) => (value ? `${(value * 100).toFixed(0)}%` : null), // format values as percentages
              color: colors.gray[500],
              anchor: "start",
              align: "end",
              font: {
                size: 20,
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              stacked: true,
              min: 0,
              max: 1,
              ticks: {
                display: false,
                stepSize: 1,
                format: {
                  style: "percent",
                  maximumFractionDigits: 1,
                },
                callback: (value) => {
                  return `${parseFloat(value as string) * 100}%`;
                },
              },
            },
            y: {
              stacked: true,
              grid: {
                display: false,
              },
            },
          },
        }}
      />
    )
}