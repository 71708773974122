import { useState } from "react";
import useApi from "../../hooks/useApi";
import { CheckIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/16/solid";
import PopupNotification from "../../components/PopupNotification";
import Button from "../../components/Button";
import { DialogTitle } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function ContactUs() {
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { postApiData } = useApi();

  async function sendMessage() {
    setSubmitting(true);
    if (subject && message) {
      setError("");
      try {
        const { response } = await postApiData("/company/email/send", {
          subject,
          message,
        });
        if (response.status !== 200) {
          console.error(response);
          setError("An unexpected error occurred, please try again later.");
          setSubmitting(false);
          return;
        }

        setMessageSent(true);
        setSubject("");
        setMessage("");
        setSubmitting(false);
      } catch (err: any) {
        console.error(err);
      }
    } else {
      setError("Please fill out subject and message");
    }
    setSubmitting(false);
  }
  if (messageSent) {
    return (
      <div className="lg:w-1/2 mt-10 mx-auto">
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Message Sent</h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Your message has been sent to the Strata Intel team, we will get in contact with you within 24 hours
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <Link to="/home">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-strataBlue px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Back to dashboard
            </button>
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div className="relative bg-white rounded-lg max-w-7xl mx-auto lg:grid lg:grid-cols-5">
      {/* <PopupNotification show={messageSent} setShow={setMessageSent} title="Success!" message="Your message has been sent to the Strata Intel team" /> */}
      <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
        <div className="max-w-lg mx-auto">
          <h2 className="text-3xl font-extrabold tracking-tight text-blue-600 sm:text-3xl">Strata Intel Support</h2>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">Get in touch</h2>
          <p className="mt-3 text-lg leading-6 text-gray-500">
            Strata Intel is available to assist you. Let us know of any issues you may have encountered with your portal, or other ways we can
            improve your experience!
          </p>
          <dl className="mt-8 text-base text-gray-500">
            <div>
              <dt className="sr-only">Contact Suppor</dt>
              <dd>
                <p>
                  We are available <span className="font-bold">8am to 8pm</span> MST Monday to Friday.
                </p>
              </dd>
            </div>
            {/* <div className="mt-6">
              <dt className="sr-only">Phone number</dt>
              <dd className="flex">
                <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                <span className="ml-3">+1 617-249-3559</span>
              </dd>
            </div> */}
            <div className="mt-3">
              <dt className="sr-only">Email</dt>
              <dd className="flex">
                <EnvelopeIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                <span className="ml-3">support@strataintel.com</span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
        <div className="max-w-lg mx-auto lg:max-w-none">
          <div className="grid grid-cols-1 gap-y-6">
            <div>
              <label htmlFor="subject" className="sr-only">
                Subject
              </label>
              <input
                type="text"
                name="full-name"
                id="full-name"
                autoComplete="name"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded"
                placeholder="Subject"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              />
            </div>

            <div>
              <label htmlFor="message" className="sr-only">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border border-gray-300 rounded"
                placeholder="Message"
                defaultValue={""}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
            </div>
            {error && <span className="text-sm text-red-500">{error}</span>}
            <div>
              <Button text="Send" onClick={sendMessage} submitting={submitting} size="lg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
