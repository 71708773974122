import { useContext } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import { useQuery } from "@tanstack/react-query";
import { Survey } from "../../types";
import useApi from "../../hooks/useApi";
import { getLatestCompletedSurvey } from "../../hooks/helpers";
import Container from "../../components/Container";
import EconomicImpact from "../Home/EconomicImpact";
import { ArrowUturnDownIcon, LockClosedIcon, MagnifyingGlassIcon, PresentationChartBarIcon } from "@heroicons/react/16/solid";

export default function Risk() {
  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();
  const companyId = companyContext.companyId;

  const {
    isPending: surveysIsPending,
    error: surveysError,
    data: surveys,
  } = useQuery<Survey[]>({
    queryKey: ["surveys", companyId],
    queryFn: () => getApiData(`/company/surveys`),
    enabled: !!companyId,
  });

  const latestCompletedSurvey = surveys ? getLatestCompletedSurvey(surveys) : null;

  return (
    <div>
      <h1 className="text-2xl font-bold">Economic Risk Impact</h1>
      {latestCompletedSurvey ? (
        <Container title="" className="mt-5 border-2 border-red-500">
          <EconomicImpact />
        </Container>
      ) : (
        <div className="flex items-center justify-center h-96">
          <div className="flex flex-col justify-center content-center items-center">
            <LockClosedIcon className="h-14 w-14 mb-4 bg-yellow-400 rounded-full p-4" />
            <h1 className="text-xl font-bold">No Economic Risk Impact - Yet</h1>
            <p className="mt-4 text-gray-500 text-center">Come back after your first assessment is complete</p>
          </div>
        </div>
      )}
    </div>
  );
}
