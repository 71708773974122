import { useContext, useState } from "react";
import { CognitoAdmin, CompanyRole, UserCompanyRole } from "../../types";
import Button from "../../components/Button";
import AdminModal from "./AdminModal";
import useApi from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { CompanyContext } from "../../contexts/companyContext";
import Spinner from "../../components/Spinner";
import { PlusIcon } from "@heroicons/react/24/outline";

export default function ManageAdmins() {
  const { getApiData } = useApi();
  const companyContext = useContext(CompanyContext);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedAdmin, setSelectedAdmin] = useState<CognitoAdmin>();

  function getValueByName(
    name: string,
    attributes: Array<{ Name: string; Value: string }>
  ): string | undefined {
    const pair = attributes.find((pair) => pair.Name === name);
    return pair ? pair.Value : undefined;
  }

  const {
    isPending: adminsIsPending,
    data: admins,
  } = useQuery<CognitoAdmin[]>({
    queryKey: ["admins", companyContext.companyId],
    queryFn: async () => getApiData(`/company/admin`),
    initialData: []
  });

  const { data: userCompanyRoles } = useQuery<UserCompanyRole[]>({
    queryKey: ["userCompanyRoles", companyContext.companyId],
    queryFn: async () => getApiData(`/company/roles/users`),
    initialData: [],
  });

  if(adminsIsPending) {
    <Spinner />
  }
  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
      <div className="px-4 sm:px-0 pb-5">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Manage Admins</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">These are individuals with access to the Strata Intel dashboard</p>
      </div>
        <Button
          text="Add New"
          className="h-10 mt-4"
          onClick={() => {
            setSelectedAdmin(undefined);
            setOpenModal(true);
          }}
          icon={PlusIcon}
        />
      </div>
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Given Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Family Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  ></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {admins.map((admin, index: number) => (
                  <tr key={index}>
                    <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {getValueByName("email", admin.UserAttributes)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                      {getValueByName("given_name", admin.UserAttributes)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                      {getValueByName("family_name", admin.UserAttributes)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                     {userCompanyRoles.find(role => role.cognito_user_id === admin.Username)?.company_role_name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                      <Button
                        text="View"
                        onClick={() => {
                          setSelectedAdmin(admin);
                          setOpenModal(true);
                        }}
                        variant="secondary"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <AdminModal
              open={openModal}
              setOpen={setOpenModal}
              details={selectedAdmin}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
