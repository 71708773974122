import { useState, useRef } from "react";
import ReactDOM from "react-dom";

export default function AutocompleteField(props: {
  value: string;
  setValue: (value: string) => void;
  values: string[];
  placeholder?: string;
}) {
  const { value, values, setValue, placeholder } = props;
  const [filteredValues, setFilteredValues] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const renderDropdown = () => {
    if (!showDropdown || !inputRef.current) return null;

    const { top, left, width, height } = inputRef.current.getBoundingClientRect();

    return ReactDOM.createPortal(
      <ul
        className="absolute z-50 mt-1 bg-white border border-gray-300 rounded-md max-h-60 overflow-y-auto shadow-lg sm:text-sm sm:leading-6"
        style={{ top: top + height + window.scrollY, left, width }}
      >
        {filteredValues.map((value, index) => (
          <li
            key={index}
            onClick={() => {
                const createMatch = value.match(/^Create "(.+)"$/);
                if(createMatch) {
                    setValue(createMatch[1]);
                    return;
                }
              setValue(value);
              setShowDropdown(false);
            }}
            className="p-2 cursor-pointer hover:bg-gray-200"
          >
            {value}
          </li>
        ))}
      </ul>,
      document.body
    );
  };

  return (
    <div className="relative">
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={(e) => {
          const inputValue = e.target.value;
          setValue(inputValue);

          let filtered = values.filter((value) =>
            value.toLowerCase().includes(inputValue.toLowerCase())
          );
          if (inputValue.length > 0 && !filtered.find(v => v === inputValue)) {
            filtered.push(`Create "${inputValue}"`);
          }
          setFilteredValues(filtered);
          setShowDropdown(true);
        }}
        onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
        onFocus={() => {
          setFilteredValues(values);
          setShowDropdown(true);
        }}
        placeholder={placeholder ?? "Search or Create New..."}
        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-strataBlue py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
      />
      {renderDropdown()}
    </div>
  );
}
