import { useEffect, useState } from "react";
import Tooltip from "./Tooltip";

// 12am = 0
// 1am = 1
// ...
// 11am = 11
// 12pm = 12
// 1pm = 13
// ...
// 11pm = 23
export default function TimePicker(props: {
  hour: number;
  setHour: (value: number) => void;
  minute: number;
  setMinute: (value: number) => void;
}) {
  const { hour, setHour, minute, setMinute } = props;
  const [selectedAMPM, setSelectedAMPM] = useState<string>(hour >= 12 ? "pm" : "am");

  function convertTo24Hour(displayHour: number, ampm: string): number {
    if (ampm === "am" && displayHour === 12) {
      return 0;
    }
    if (ampm === "pm" && displayHour !== 12) {
      return displayHour + 12;
    }
    return displayHour;
  }

  function convertToDisplayHour(hour: number): number {
    if (hour === 0) {
      return 12;
    }
    if (hour > 0 && hour <= 12) {
      return hour;
    }
    return hour - 12;
  }

  useEffect(() => {
    if(hour >= 12) {
      setSelectedAMPM("pm")
    } else {
      setSelectedAMPM("am")
    }
  }, [hour]);
  
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <div className="">
      <div className="sm:flex">
        <div className="flex">
          <select
            id="location"
            name="location"
            className="block pl-4 pr-4 text-lg bg-none h-10 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-strataBlue sm:text-sm sm:leading-6"
            value={convertToDisplayHour(hour)}
            onChange={(e) => setHour(convertTo24Hour(parseInt(e.target.value), selectedAMPM))}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
          <span className="text-xl mx-2 mt-1">:</span>
          <select
            name="minutes"
            className="block pl-4 pr-4 text-lg bg-none h-10 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-strataBlue sm:text-sm sm:leading-6"
            value={minute}
            onChange={(e) => setMinute(parseInt(e.target.value))}
          >
            <option value="0">00</option>
            <option value="30">30</option>
          </select>
          <select
            name="ampm"
            className="ml-2 block pl-4 pr-4 text-lg bg-none h-10 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-strataBlue sm:text-sm sm:leading-6"
            value={selectedAMPM}
            onChange={(e) => {
              const ampm = e.target.value;
              setSelectedAMPM(ampm);
              setHour(convertTo24Hour(convertToDisplayHour(hour), ampm));
            }}
          >
            <option value="am">AM</option>
            <option value="pm">PM</option>
          </select>
        </div>
        <div className="flex">
          <div className="mt-2 ml-3 text-gray-500 text-sm">{timezone}</div>
          <span className="ml-1 mt-2">
            <Tooltip message="This is set according to your system's settings" />
          </span>
        </div>
      </div>
    </div>
  );
}
