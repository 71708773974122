import { CSSProperties } from "react";
import GaugeComponent from "react-gauge-component";
import colors from "tailwindcss/colors";
import GaugeLegend from "../Home/GaugeLegend";

function generateTooltip(description: string, implication: string): string {
  return `<ul>
            <li style="font-size: 15px"><b>${description}</b></li>
            </li style="font-size: 12px"><i>Implications: ${implication}</i></li>
          </ul>`;
}

const tooltipStyle: CSSProperties = {
  background: "rgba( 255, 255, 255, 0.85 )",
  boxShadow: "0 8px 12px 0 rgba( 31, 38, 31, 0.17 )",
  backdropFilter: "blur( 4px )",
  WebkitBackdropFilter: "blur( 4px )",
  borderRadius: "10px",
  textShadow: "none",
  color: colors.gray[600],
  border: "none",
  //maxWidth: "500px"
};

export default function SIValueGuage(props: { siValue: number; lastSIValue?: number }) {
  const { siValue, lastSIValue } = props;

  let changePercentage = 0;

  if (lastSIValue) {
    changePercentage = Math.round(((siValue - lastSIValue) / lastSIValue) * 100);
  }

  return (
    <div className="h-5/6 flex items-center justify-center">
      <div className="flex flex-col justify-center items-center w-11/12">
      <GaugeComponent
      className=""
        type="semicircle"
        style={{ height: "100%", width: "100%" }}
        arc={{
          // width: 0.12,
          padding: 0.02,
          cornerRadius: 1,
          // gradient: true,
          subArcs: [
            {
              limit: 33,
              color: colors.red[400],
              showTick: true,
              tooltip: {
                text: generateTooltip(
                  "Stress is impacting team's concentration and focus.",
                  `Stressors are becoming unsustainable. Episodic periods of
                    high performance at times dip into this range, however this range of stress
                    cannot be maintained over time. Consideration of mental health support
                    for affected team members. Increase leader communication.`
                ),
                style: tooltipStyle,
              },
            },
            {
              limit: 66,
              color: colors.yellow[400],
              showTick: true,
              tooltip: {
                text: generateTooltip(
                  "Teams routinely experiencing stress that impacts concentration and focus.",
                  `Stressors are becoming unsustainable. Episodic periods of
                    high performance at times dip into this range, however this range of stress
                    cannot be maintained over time. Consideration of mental health support
                    for affected team members. Increase leader communication.`
                ),
                style: tooltipStyle,
              },
            },
            {
              limit: 100,
              color: colors.green[400],
              showTick: true,
              tooltip: {
                text: generateTooltip(
                  "Teams consistently put forth effort and experience appropriate stress levels.",
                  "Healthy urgency and productivity. Fully engaged team running at optimal levels of performance."
                ),
                style: tooltipStyle,
              },
            },
          ],
        }}
        pointer={{
          type: "arrow",
          color: colors.gray[500],
        }}
        labels={{
          valueLabel: {
            formatTextValue: (value) => `${value}`,
            style: { textShadow: "none" },
            matchColorWithArc: true,
          },
          tickLabels: {
            type: "outer",
            defaultTickValueConfig: {
              formatTextValue: (value) => `${value}`,
            },
          },
        }}
        value={siValue}
        minValue={0}
        maxValue={100}
      />
      {(lastSIValue && !Number.isNaN(lastSIValue)) && (
        <div className="text-center w-full text-gray-500 text-xs -mt-3 mb-4">
          Since last month:{" "}
          {changePercentage >= 0 ? (
            <span className="text-green-500">Increase {changePercentage}%</span>
          ) : (
            <span className="text-red-500">Decrease {changePercentage}%</span>
          )}
        </div>
      )}
        <GaugeLegend />
      </div>
    </div>
  );
}
