const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function classNames(...classes: Array<any>) {
  return classes.filter(Boolean).join(" ");
}

function generateDays(selectedDate: Date) {
  const year = selectedDate.getFullYear();
  const month = selectedDate.getMonth();
  const selectedDay = selectedDate.getDate();
  const numDaysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const lastDayOfPrevMonth = new Date(year, month, 0).getDate();

  const days: Array<{
    date: number;
    isCurrentMonth?: boolean;
    isSelected?: boolean;
    isToday?: boolean;
  }> = [];

  // Add days from the previous month
  for (let i = 1; i <= firstDayOfMonth; i++) {
    const prevMonthDay = lastDayOfPrevMonth - (firstDayOfMonth - i);
    days.push({ date: prevMonthDay });
  }

  // Add days from the current month
  for (let i = 1; i <= numDaysInMonth; i++) {
    const newDate = new Date(year, month, i);
    days.push({
      date: newDate.getDate(),
      isCurrentMonth: true,
      isSelected: selectedDay === i,
      isToday: new Date().getMonth() === month && new Date().getDate() === i,
    });
  }

  // Calculate the total number of days needed to fill the grid
  const totalDaysInGrid = Math.ceil((firstDayOfMonth + numDaysInMonth) / 7) * 7;

  // Add days from the next month
  for (let i = 1; days.length < totalDaysInGrid; i++) {
    days.push({ date: i });
  }

  return days;
}

export default function CalendarPreview(props: { date: Date }) {
  const { date } = props;

  const days = generateDays(date);
  return (
    <div  className="relative">
        <div className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9">
          <div className="flex items-center text-gray-900">
            <div className="flex-auto text-sm font-semibold text-gray-600">
              {months[date.getMonth()]} {date.getFullYear()}
            </div>
          </div>
          <div className="mt-2 grid grid-cols-7 text-xs text-gray-500">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </div>
          <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-[12px] shadow ring-1 ring-gray-200">
            {days.map((day, dayIdx) => (
              <div
                key={dayIdx}
                className={classNames(
                  "py-1.5 focus:z-10 cursor-default",
                  day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                  (day.isSelected || day.isToday) && "font-semibold",
                  day.isSelected && "text-white",
                  !day.isSelected && day.isCurrentMonth && !day.isToday && "text-gray-900",
                  !day.isSelected && !day.isCurrentMonth && !day.isToday && "text-gray-400",
                  day.isToday && !day.isSelected && "text-strataBlue",
                  dayIdx === 0 && "rounded-tl-lg",
                  dayIdx === 6 && "rounded-tr-lg",
                  dayIdx === days.length - 7 && "rounded-bl-lg",
                  dayIdx === days.length - 1 && "rounded-br-lg"
                )}
              >
                <time
                  dateTime={day.date.toString()}
                  className={classNames(
                    "mx-auto flex h-5 w-5 items-center justify-center rounded-full",
                    day.isSelected && day.isToday && "bg-strataBlue",
                    day.isSelected && !day.isToday && "bg-strataBlue"
                  )}
                >
                  {day.date}
                </time>
              </div>
            ))}
          </div>
      </div>
      <div className="mt-2 text-gray-700 text-sm">
        {months[date.getMonth()]} {date.getDate()}, {date.getFullYear()}{" "}
        <span className="text-gray-500">{date.toLocaleTimeString()}</span>
      </div>
    </div>
  );
}
