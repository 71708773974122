import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/outline";
import {
  calculatePercentageChange,
  calculateStressCategoryCounts,
  filterImpairedRespondents,
  getLatestCompletedSurvey,
  getPreviousCompletedSurvey,
  percentageChange,
} from "../../hooks/helpers";
import { useQuery } from "@tanstack/react-query";
import { Dataset, Participant, Respondent, Survey } from "../../types";
import { useContext, useState } from "react";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import EconomicImpactTable from "./EconomicImpactTable";
import TrendChart from "../../components/TrendChart";
import colors from "tailwindcss/colors";
import { RadioGroup, Radio } from "@headlessui/react";
import Divider from "../../components/Divider";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export default function EconomicImpact() {
  const filterOptions = ["Turnover", "Sequential Task", "Workers Comp", "Major Crisis" ];
  const [selectedMetricFilters, setSelectedMetricFilters] = useState<Array<String>>(filterOptions);
  const [selectedSince, setSelectedSince] = useState<string>("Last Month");

  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();
  const companyId = companyContext.companyId;

  const { data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys", companyId],
    queryFn: () => getApiData(`/company/surveys`),
    enabled: !!companyId,
    initialData: [],
  });

  const latestCompletedSurvey = surveys ? getLatestCompletedSurvey(surveys) : null;

  const { data: latestRespondents } = useQuery<Respondent[]>({
    queryKey: ["respondents", latestCompletedSurvey?.survey_id],
    queryFn: async () => getApiData(`/company/surveys/respondents/${latestCompletedSurvey?.survey_id}`),
    enabled: !!latestCompletedSurvey,
    initialData: [],
  });
  
  // Get all respondents for a company no matter the survey
  const { isPending: allRespondentsIsPending, data: allRespondents } = useQuery<Respondent[]>({
    queryKey: ["allRespondents", companyContext.companyId],
    queryFn: async () => getApiData(`/company/surveys/all/respondents-with-groups`),
    initialData: [],
  });

  const {
    isPending: participantsIsPending,
    error: participantsError,
    data: participants,
  } = useQuery<Participant[]>({
    queryKey: ["participants", companyContext.companyId],
    queryFn: async () => getApiData(`/company/users`),
    initialData: [],
  });

  const OPTIMAL_REVENUE = 5000000;
  const BAD_DEBT = 0.005;
  const LATEST_SURVEY_NUM_EMPLOYEES = participants.length;
  const AVG_SALARY = 35000;
  const COST_PERCENTAGE_SALARY = 0.75;
  const COST_TURNOVER_PER_EMPLOYEE = AVG_SALARY * COST_PERCENTAGE_SALARY;
  const AVG_COST_PER_INJURY = 41757;
  const AVERAGE_SETTLEMENT = 400000;

  function calculateSequntialTask(red: number, yellow: number, gray: number, totalRecipients: number): number {
    const REVENUE_PER_EMPLOYEE = (OPTIMAL_REVENUE * (1 - BAD_DEBT)) / totalRecipients;
    return (((REVENUE_PER_EMPLOYEE * red) * .7) + ((REVENUE_PER_EMPLOYEE * yellow) * .5) + ((REVENUE_PER_EMPLOYEE * gray) * .3));
  }

  function calculateEmployeeTurnover(red: number, yellow: number, highGreen: number, disengaged: number): number {
    return COST_TURNOVER_PER_EMPLOYEE * (red + yellow + (.2 * highGreen) + (.3 * disengaged));
  }

  function calculateWorkersCompensation(red: number, yellow: number, disengaged: number): number {
    return (red + yellow + (disengaged * .3)) * AVG_COST_PER_INJURY;
  }

  function calculateMajorCrisis(red: number, highYellow: number, midYellow: number, disengaged:number): number {
    return (AVERAGE_SETTLEMENT * red) + (AVERAGE_SETTLEMENT * highYellow * .7) + (AVERAGE_SETTLEMENT * midYellow * .5) + (AVERAGE_SETTLEMENT * disengaged * .3) 
  }

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const { disengaged , red, yellow, green, midGreen, highGreen, lowYellow, midYellow, highYellow } = calculateStressCategoryCounts(latestRespondents);

  const employeeTurnoverCost = calculateEmployeeTurnover(red, yellow, highGreen, disengaged);
  const sequentialTaskCost = calculateSequntialTask(red, yellow, disengaged, LATEST_SURVEY_NUM_EMPLOYEES);
  const workersCompensationCost = calculateWorkersCompensation(red, yellow, disengaged);
  const majorCrisisRiskCost = calculateMajorCrisis(red, highYellow, midYellow, disengaged);

  let totalBalanceTemp = 0;

  if (selectedMetricFilters.includes("Sequential Task")) {
    totalBalanceTemp += sequentialTaskCost;
  }
  if (selectedMetricFilters.includes("Turnover")) {
    totalBalanceTemp += employeeTurnoverCost;
  }
  if (selectedMetricFilters.includes("Workers Comp")) {
    totalBalanceTemp += workersCompensationCost;
  }
  if (selectedMetricFilters.includes("Major Crisis")) {
    totalBalanceTemp += majorCrisisRiskCost;
  }

  let stressCategoryDatasets: Dataset[] = [
    {
      label: "Sequential Task",
      data: [],
      color: colors.purple[400],
    },
    {
      label: "Turnover",
      data: [],
      color: colors.yellow[400],
    },
    {
      label: "Workers Comp",
      data: [],
      color: colors.green[400],
    },
    {
      label: "Major Crisis",
      data: [],
      color: colors.red[500],
    },
    {
      label: "Total",
      data: [],
      color: colors.black,
    },
  ];


  for (let i = 0; i < surveys.length; i++) {

    if(new Date(surveys[i].end_date) > new Date()) {
      continue; // skip surveys in future
    }

    const respondentsForSuvey = allRespondents.filter((r) => r.survey_id === surveys[i].survey_id);

    const { disengaged, red, yellow, green, midGreen, highGreen, lowYellow, midYellow, highYellow } = calculateStressCategoryCounts(respondentsForSuvey);

    let surveyCurrentRecipientCount = surveys[i].total_recipients;

    let surveySquentialCost = calculateSequntialTask(red, yellow, disengaged, surveyCurrentRecipientCount ?? 0);
    let surveyEmployeeTurnoverCost = calculateEmployeeTurnover(red, yellow, highGreen, disengaged);
    let surveyWorkersCompensationCost = calculateWorkersCompensation(red, yellow, disengaged);
    let surveyMajorCrisisRiskCost = calculateMajorCrisis(red, highYellow, midYellow, disengaged);

    stressCategoryDatasets[0].data.push({ value: surveySquentialCost, date: new Date(surveys[i].start_date) });
    stressCategoryDatasets[1].data.push({ value: surveyEmployeeTurnoverCost, date: new Date(surveys[i].start_date) });
    stressCategoryDatasets[2].data.push({ value: surveyWorkersCompensationCost, date: new Date(surveys[i].start_date) });
    stressCategoryDatasets[3].data.push({ value: surveyMajorCrisisRiskCost, date: new Date(surveys[i].start_date) });

    let totalForSurvey = 0;
    totalForSurvey += surveySquentialCost += surveyEmployeeTurnoverCost += surveyWorkersCompensationCost += surveyMajorCrisisRiskCost;

    // If you want one total line that is affected by the filters uncomment this code
    // if (selectedMetricFilters.includes('Sequential Task')) {
    //   totalForSurvey += surveySquentialCost;
    // }
    // if (selectedMetricFilters.includes('Turnover')) {
    //   totalForSurvey += surveyEmployeeTurnoverCost;
    // }
    // if (selectedMetricFilters.includes('Workers Comp')) {
    //   totalForSurvey += surveyWorkersCompensationCost;
    // }
    // if (selectedMetricFilters.includes('Major Crisis')) {
    //   totalForSurvey += surveyMajorCrisisRiskCost;
    // }

    stressCategoryDatasets[4].data.push({
      value: totalForSurvey,
      date: new Date(surveys[i].start_date),
    });
  }

  const handleFilterChange = (filter: any) => {
    setSelectedMetricFilters((prevFilters: any[]) => {
      if (prevFilters.includes(filter)) {
        // Remove the filter if it’s already selected
        return prevFilters.filter((f: any) => f !== filter);
      } else {
        // Add the filter if it’s not selected
        return [...prevFilters, filter];
      }
    });
  };

  const filteredDatasets = stressCategoryDatasets.filter((dataset) => selectedMetricFilters.includes(dataset.label));


  let selectedSinceMaxLength: number = 0;

  if (selectedSince == "Last Month") {
    selectedSinceMaxLength = 2;
  }
  if (selectedSince == "Last Quarter") {
    selectedSinceMaxLength = 3;
  }
  if (selectedSince == "Last Year") {
    selectedSinceMaxLength = 12;
  }

  let valuesToCompare: { metricName: string; compareValue: number }[] = [];


  for (const metric of stressCategoryDatasets) {
    //@ts-ignore
    metric.data.sort((a,b) => a.date - b.date)
    if (metric.data.length >= selectedSinceMaxLength) {
      valuesToCompare.push({ metricName: metric.label, compareValue: metric.data[metric.data.length - selectedSinceMaxLength].value });
    } else {
      valuesToCompare.push({ metricName: metric.label, compareValue: 0 });
    }
  }

  let sequentialTaskPercentChange = valuesToCompare[0].compareValue;
  let TurnOverPercentageChange = valuesToCompare[1].compareValue;
  let workersCompensationPercentageChange = valuesToCompare[2].compareValue;
  let majorCrisisPercentageChange = valuesToCompare[3].compareValue;

  let sequentialTaskValueChange = sequentialTaskCost - valuesToCompare[0].compareValue;
  let TurnOverValueChange = employeeTurnoverCost - valuesToCompare[1].compareValue;
  let workersCompensationValueChange = workersCompensationCost - valuesToCompare[2].compareValue;
  let majorCrisisValueChange = majorCrisisRiskCost - valuesToCompare[3].compareValue;

  // Economic Metrics for the latest completed Assessment
  let stats: { name: string; value: number; change: number; percentageChange: number }[] = [
    { 
      name: "Turnover Cost Risk", 
      value: employeeTurnoverCost, 
      change: TurnOverValueChange, 
      percentageChange: TurnOverPercentageChange 
    },
    {
      name: "Sequential Task Error Risk",
      value: sequentialTaskCost,
      change: sequentialTaskValueChange,
      percentageChange: sequentialTaskPercentChange,
    },
    {
      name: "Workers Compensation Risk",
      value: workersCompensationCost,
      change: workersCompensationValueChange,
      percentageChange: workersCompensationPercentageChange,
    },
    { name: "Major Crisis Risk", value: majorCrisisRiskCost, change: majorCrisisValueChange, percentageChange: majorCrisisPercentageChange },
  ];

  return (
    <div className="mx-auto">
      <div className="flex flex-col">
        <p className="text-gray-400 font-semibold text-md mb-3">Current Total Risk Balance</p>
        <h2 className="text-3xl font-bold mb-4">{formatter.format(totalBalanceTemp)}</h2>
      </div>
      <div className="h-60">
        <TrendChart datasets={filteredDatasets} stepSize={500000} calcType="add" hideLegend={false} hideRangeSelector={true} suggestedMin={0} />
      </div>

      <div className="flex flex-wrap mt-6 mb-6 pl-4 items-center">
        <p className="hidden md:block mr-3 text-sm font-medium text-gray-500">Filters</p>

        <fieldset aria-label="Filter Metrics">
          <RadioGroup className="grid grid-cols-2 gap-3 md:grid-cols-6">
            {filterOptions.map((filter) => (
              <Radio
                key={filter}
                value={filter}
                onClick={() => handleFilterChange(filter)}
                className={classNames(
                  selectedMetricFilters.includes(filter) ? "cursor-pointer focus:outline-none bg-red-200 opacity-70" : "bg-gray-200",
                  "flex items-center justify-center rounded-full text-xs font-semibold px-4 py-1 select-none border border-red-500"
                )}
              >
                {filter}
              </Radio>
            ))}
          </RadioGroup>
        </fieldset>
      </div>
      {/* <p className="pl-3 mb-3 font-light text-xs text-red-500">Most Recent Month</p> */}
      <EconomicImpactTable stats={stats} selectedSince={selectedSince} setSelectedSince={setSelectedSince} />
      <div className="mt-5 text-xs text-gray-700">*Based on averages in your industry</div>
    </div>
  );
}
