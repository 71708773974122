import { useContext, useEffect, useState } from "react";
import PopupNotification from "../../components/PopupNotification";
import HRSystemRequestModal from "../../components/HRSystemRequestModal";
import UserUpload from "../Participants/UserUpload";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Participant } from "../../types";
import { CompanyContext } from "../../contexts/companyContext";
import useApi from "../../hooks/useApi";
import { CheckIcon } from "@heroicons/react/24/outline";

export default function UploadParticipantsStep(props: { onUpload?: () => void }) {
  const [openContact, setOpenContact] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);

  const companyContext = useContext(CompanyContext);
  const { getApiData } = useApi();

  const { data: participants } = useQuery<Participant[]>({
    queryKey: ["participants", companyContext.companyId],
    queryFn: async () => getApiData(`/company/users`),
    initialData: [],
  });

  useEffect(() => {
    if (participants.length > 0) {
      setUploaded(true);
    }
  }, [participants]);

  return (
    <div className="h-full">
      <div className="grid h-5/6">
        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div className="sm:col-span-6 h-full">
            {uploaded ? (
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <CheckIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <div className="text-base font-semibold leading-6 text-gray-900">Users uploaded</div>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">Users successfully added, please continue</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="">
                <label className="mb-4 block text-sm text-stone-500 ">
                  <Link
                    to="/Strata_Intel_User_Upload_Template.csv"
                    target="_blank"
                    download
                    className="text-sm text-strataBlue decoration-2 hover:underline font-medium focus:outline-none focus:underline mr-1"
                  >
                    Download a sample CSV template
                  </Link>
                  to see an example of the format required.
                </label>
                <UserUpload
                  onUpload={() => {
                    setUploaded(true);
                    props.onUpload && props.onUpload();
                  }}
                />
              </div>
            )}
            <div className="relative mt-5">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-900">Or</span>
              </div>
            </div>
            <p
              onClick={() => setOpenContact(true)}
              className="mt-3 text-center text-sm font-semibold leading-6 text-strataBlue hover:underline cursor-pointer"
            >
              Automate this step
            </p>
            <HRSystemRequestModal open={openContact} setOpen={setOpenContact} />
          </div>
        </div>
      </div>
    </div>
  );
}
