import DayOfWeek from "./DayOfWeek";
import WeekOfMonth from "./WeekOfMonth";
import TimePicker from "./TimePicker";
import CalendarPreview from "./CalendarPreview";
import { getNextScheduledDate } from "../hooks/helpers";

export default function Schedule(props: {
  selectedWeek: number;
  setSelectedWeek: (value: number) => void;
  selectedDay: number;
  setSelectedDay: (value: number) => void;
  selectedHour: number;
  setSelectedHour: (value: number) => void;
  selectedMinute: number;
  setSelectedMinute: (value: number) => void;
}) {
  const {
    selectedWeek,
    setSelectedWeek,
    selectedDay,
    setSelectedDay,
    selectedHour,
    setSelectedHour,
    selectedMinute,
    setSelectedMinute,
  } = props;

  return (
    <div className="w-full sm:flex justify-between">      
      <div className="sm:w-3/5">
        <div className="mt-5">
          <div className="sm:col-span-2">
            <label
              htmlFor="weekOfMonth"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Week of the Month
            </label>
            <div className="mt-2">
              <WeekOfMonth week={selectedWeek} setWeek={setSelectedWeek} />
            </div>
          </div>
        </div>

        <div className="mt-10">
          <div className="sm:col-span-2">
            <label
              htmlFor="dayOfWeek"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Day of the Week
            </label>

            <div className="mt-2">
              <DayOfWeek day={selectedDay} setDay={setSelectedDay} />
            </div>

            <label
              htmlFor="dayOfWeek"
              className="mt-2 block text-sm font-medium leading-6 text-gray-500"
            >
              Note: Tuesday Recommended
            </label>
          </div>
        </div>

        <div className="mt-7">
          <div className="sm:col-span-2">
            <label
              htmlFor="dayOfWeek"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Time
            </label>

            <div className="mt-2">
              <TimePicker
                hour={selectedHour}
                setHour={setSelectedHour}
                minute={selectedMinute}
                setMinute={setSelectedMinute}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="sm:w-2/5 sm:ml-24">
        <div className="w-full text-center mt-3 text-sm font-medium leading-6 text-gray-900 mb-5">Next Assessment Date</div>
        <CalendarPreview date={getNextScheduledDate({
          week_of_month: selectedWeek,
          day_of_week: selectedDay,
          hour: selectedHour,
          minute: selectedMinute,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })}/>
      </div>
    </div>
  );
}
