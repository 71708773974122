import { useContext, useState } from "react";
import { ClipboardDocumentIcon, UserGroupIcon, CreditCardIcon, BellAlertIcon, UserIcon, IdentificationIcon } from "@heroicons/react/24/outline";

import Schedule from "./Schedule";
import ManageAdmins from "./ManageAdmins";
import ManagePayment from "./ManagePayment";
import Account from "./Account";
import Notifications from "./Notifications";
import Participants from "./Participants";
import { useQuery } from "@tanstack/react-query";
import { Company } from "../../types";
import useApi from "../../hooks/useApi";
import { CompanyContext } from "../../contexts/companyContext";
import Spinner from "../../components/Spinner";
import { Link, useLocation, useSearchParams } from "react-router-dom";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Settings() {
  const location = useLocation();  
  const { getApiData } = useApi();
  const { companyId, showAllAccounts } = useContext(CompanyContext);

  const currentTabId = location.pathname.split("/")[2] ?? "account";

  const { data: company } = useQuery<Company>({
    queryKey: ["company", companyId],
    queryFn: () => getApiData(`/company/info`),
  });

  if (!company) {
    return <Spinner />;
  }

  const tabs = [
    { id: "account", name: "Account", icon: UserIcon, show: true },
    { id: "assessments", name: "Assessments", icon: ClipboardDocumentIcon, show: company.has_assessments && !showAllAccounts },
    { id: "admins", name: "Admins", icon: UserGroupIcon, show: !showAllAccounts },
    { id: "subscription", name: "Subscription", icon: CreditCardIcon, show: company.is_billing && !showAllAccounts },
    { id: "participants", name: "Participants", icon: IdentificationIcon, show: company.has_assessments && !showAllAccounts },
  ];

  return (
    <>
      <div className="mx-auto max-w-full lg:flex lg:gap-x-8 lg:px-2">
        <h1 className="sr-only">General Settings</h1>

        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-2">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {tabs
                .filter((t) => t.show)
                .map((tab) => (
                  <li key={tab.name}>
                    <Link to={`/settings/${tab.id}`}>
                      <div
                        className={classNames(
                          tab.id === currentTabId ? "bg-gray-50 text-strataBlue" : "text-gray-700 hover:bg-gray-50 hover:text-strataBlue",
                          "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 cursor-pointer"
                        )}
                      >
                        <tab.icon aria-hidden="true" className={classNames(tab.id === currentTabId ? "text-strataBlue" : "text-gray-400 group-hover:text-strataBlue", "h-6 w-6 shrink-0")} />
                        {tab.name}
                      </div>
                    </Link>
                  </li>
                ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-2 sm:px-2 lg:flex-auto lg:px-0 lg:py-2">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            {/* Account Settings */}
            {currentTabId === "account" && <Account />}

            {/* Assessment Settings */}
            {currentTabId === "assessments" && <Schedule />}

            {/* Admin Settings */}
            {currentTabId === "admins" && <ManageAdmins />}

            {/* Subscription Settings */}
            {currentTabId === "subscription" && <ManagePayment />}

            {/* Notification Settings */}
            {currentTabId === "notifications" && <Notifications />}

            {/* Participant Settings */}
            {currentTabId === "participants" && <Participants />}
          </div>
        </main>
      </div>
    </>
  );
}
