import React from "react";
import { Link } from "react-router-dom";

function InfoCard(props: {
  title: string;
  impact: string;
  subHeader: string;
  subHeaderColor?: string;
  subHeader2?: string;
  subHeader2Color?: string;
  description: string;
  description2?: string;
  className?: string;
  route: string;
}) {
  const { title, impact, subHeader, subHeaderColor, subHeader2, subHeader2Color, description, description2, className, route } = props;

  return (
    <div className={`border p-4 w-full max-w-content relative ${className ?? ""}`}>
      <div className="flex items-center mb-2">
        <p className="font-bold text-sm text-black mr-2 whitespace-nowrap truncate">{title}</p>
        <span className="text-gray-400">|</span>
        <p className="text-gray-400 ml-2 truncate">{impact}</p>
      </div>
      <div className="flex justify-between">
        <div className="flex-col">
          <p className="mb-2" style={{color: subHeaderColor}}>{subHeader}</p>
          <p className="text-gray-400 mb-4 break-words">{description}</p>
        </div>
        {subHeader2 ? (
          <div className="flex-col">
            <p className="mb-2" style={{color: subHeader2Color}}>{subHeader2}</p>
            <p className="text-gray-400 mb-4 break-words">{description2}</p>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="absolute bottom-2 right-2 text-blue-500 text-sm cursor-pointer">
        <Link to={`/${route}`}><p>View Details</p></Link>
      </div>
    </div>
  );
}

export default InfoCard;
