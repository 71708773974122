import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ReactNode } from "react";

export default function Tooltip(props: { message: string; position?: "above" | "below"; children?: ReactNode }) {
  const position = props.position ? props.position : "above";

  return (
    <div className="">
      <div className="group relative inline-block text-gray-400 underline duration-300">
        {props.children ? props.children : <InformationCircleIcon className="h-5 w-5" aria-hidden="true" />}
        <span
          className={`${
            position === "above" ? "-translate-y-[105%] after:top-[100%]" : "translate-y-[60%] after:bottom-[100%] after:rotate-180"
          } absolute hidden group-hover:flex left-1/2 transform -translate-x-1/2 right-3 -top-2 min-w-32 max-w-80 px-3 py-3
           rounded-lg text-center text-gray-600 text-sm after:content-[''] after:absolute after:left-1/2 
            after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-white
            z-10
            shadow-lg ring-1 ring-black/5 backdrop-filter backdrop-blur-[2px] bg-white bg-opacity-90`}
        >
          {props.message}
        </span>
      </div>
    </div>
  );
}
