import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import Button from "./Button";

export default function Table(props: {
  items: Array<any>;
  structure: Array<{
    header: string;
    column: string;
    alignment?: "r" | "l";
    format?: "date" | "cents";
    customFormat?: (text: string | number) => string;
    link?: {
      text: string;
      href: string;
    };
    button?: {
      text: string;
      onClick: (value: string) => void;
    };
  }>;
  limitPerPage: number;
}) {
  const { items, structure, limitPerPage } = props;

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentItems, setCurrentItems] = useState<Array<any>>([]);

  const range = (start: number, end: number) => {
    //@ts-ignore
    return [...Array(end).keys()].map((el) => el + start);
  };

  const start = (currentPage - 1) * limitPerPage + 1;
  const end =
    currentPage * limitPerPage <= items.length
      ? currentPage * limitPerPage
      : items.length;
  const pagesCount = Math.ceil(items.length / limitPerPage);
  const pages = range(1, pagesCount);

  useEffect(() => {
    setCurrentItems(items.slice(start - 1, end));
  }, [start, end, currentPage, items]);

  return (
    <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:max-w-full lg:px-0">
      <div className="flex flex-col mt-2">
        <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                {structure.map((s, i) => (
                  <th
                    key={i}
                    className={`px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                      s.alignment === "l" && "text-right"
                    }`}
                    scope="col"
                  >
                    {s.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {currentItems.map((item: any, index: number) => (
                <tr key={index}>
                  {structure.map((s, i) => (
                    <td
                      key={i}
                      className={`px-6 py-4 text-sm text-gray-500 ${
                        s.alignment === "l" && "text-right"
                      }`}
                    >
                      {s.format === "date" ? (
                        new Date(item[s.column]).toLocaleDateString() +
                        " " +
                        new Date(item[s.column]).toLocaleTimeString()
                      ) : s.format === "cents" ? (
                        (item[s.column] / 100).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      ) : s.link ? (
                        <a
                          href={item[s.link.href]}
                          className="underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {s.link.text}
                        </a>
                      ) : s.button ? (
                        <Button
                          text={s.button.text}
                          onClick={() => s.button!.onClick(item[s.column])}
                          size="sm"
                        />
                      ) : s.customFormat ? (
                        s.customFormat(item[s.column])
                      ) : (
                        item[s.column]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex items-center justify-between border-t border-gray-200 p-2 py-3">
            <div className="flex flex-1 justify-between sm:hidden">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
                className={`${
                  currentPage === 1 && "opacity-30"
                } relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 mx-2`}
              >
                Previous
              </button>
              <p className="text-sm text-gray-600 mt-3">
                Showing <span className="font-medium">{start}</span> to{" "}
                <span className="font-medium">{end}</span> of{" "}
                <span className="font-medium">{items.length}</span> rows
              </p>
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage === pages.length}
                className={`${
                  currentPage === pages.length && "opacity-30"
                } relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 mx-2`}
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between ml-5">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{start}</span> to{" "}
                  <span className="font-medium">{end}</span> of{" "}
                  <span className="font-medium">{items.length}</span> rows
                </p>
              </div>
              <div>
                <nav
                  className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                  aria-label="Pagination"
                >
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className={`${
                      currentPage === 1 && "opacity-30"
                    } relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20`}
                    disabled={currentPage === 1}
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  {pages.map((page) => (
                    <button
                      key={page}
                      aria-current="page"
                      onClick={() => setCurrentPage(page)}
                      className={
                        page === currentPage
                          ? "relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20"
                          : "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
                      }
                      disabled={page === currentPage}
                    >
                      {page}
                    </button>
                  ))}
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className={`${
                      currentPage === pages.length && "opacity-30"
                    } relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20`}
                    disabled={currentPage === pages.length}
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
